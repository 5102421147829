@import "src/styles/mixins";

.sliding-nav-wrapper {
  overflow: auto;
  overflow-scrolling: touch;

  @include media-breakpoint-down(md) {
    position: relative;
    height: 47px;
    min-width: 315px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .sliding-nav {
    border-bottom: 1px solid var(--color-separator);
    flex-wrap: nowrap;
    white-space: nowrap;
    margin-bottom: 15px;
    @include gap(40px);

    @include media-breakpoint-down(lg) {
      @include gap(20px);
    }

    @include media-breakpoint-down(md) {
      @include gap(15px);
      position: absolute;
    }
  }

  .nav-link {
    position: relative;
    text-transform: none;
    font-size: 32px;
    line-height: 1.26;
    letter-spacing: 1px;
    padding-bottom: 25px;
    font-weight: 400;
    margin: 0;

    @include media-breakpoint-down(xl) {
      font-size: 28px;
    }
    @include media-breakpoint-down(lg) {
      font-size: 22px;
    }

    @include media-breakpoint-down(md) {
      font-size: 16px;
      padding-bottom: 18px;
    }

    &.active {
      font-weight: 600;
      border: none;

      &:after {
        content: '';
        position: absolute;
        height: 6px;
        top: calc(100% - 3px);
        border-radius: 3px;

        @include media-breakpoint-down(lg) {
          visibility: visible;
        }

        @include media-breakpoint-down(md) {
          height: 4px;
          top: calc(100% - 2px);
        }
      }
    }
  }
}
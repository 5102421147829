@import "src/styles/mixins";

.about-us-page:before {
  position: absolute;
  content: '';
  top: -96px;
  left: 0;
  width: 100vw;
  height: 81vw;
  background-image: url('../../../assets/about-us-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.about-us-page.marketing-page {
  h1.title {
    text-align: center;

    @include media-breakpoint-down(sm) {
      text-align: left;
    }
  }
}

.about-us-caption {
  margin-bottom: 160px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 70px;
  }

  .container {
    max-width: 1000px;
  }
}

.about-us-caption p {
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 126%;
  text-align: center;

  @include media-breakpoint-down(sm) {
    text-align: left;
    font-size: 24px;
  }
}

.team {
  padding-bottom: 90px;

  @include media-breakpoint-down(sm) {
    padding-bottom: 30px;
  }
}

.team .container {
  max-width: 1264px;
}

.team__heading {
  margin-bottom: 84px;
  flex-direction: column;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
  }
}

.team__heading .title {
  margin-bottom: 24px;
}

.team__heading p {
  line-height: 1.6;
  letter-spacing: 0.5px;
  max-width: 920px;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

.team__list {
  margin-top: 24px;
  margin-bottom: 48px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 0;
  }
}

.about-us-page .partnerships {
  background: rgba(35, 40, 47, 0.2);
}

.partnerships .partnerships-col {
  display: flex;
  align-items: center;
}

.investors {
  padding: 120px 0;
  background-image: url('../../../assets/investors-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include media-breakpoint-down(sm) {
    padding: 50px 0;
  }
}

.investors .container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.investors-list {
  max-width: 938px;
  text-align: center;
}

.investors .title {
  margin-bottom: 48px;
}

.add-investor-card {
  padding: 16px 0;
  text-align: center;
}

.add-investor-card__image-box {
  width: 160px;
  height: 160px;
  margin: 0 auto 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.15);
}

.add-investor-card__text {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@import 'src/styles/mixins';

.card.tiers-mobile-accordion {
  border-radius: 26px;
  margin-bottom: .5rem;
  text-align: left;
  color: var(--color-text-white-transparantize);
  border: 1px solid #2D3449;
  backdrop-filter: blur(200px);
  background-color: rgba(255, 255, 255, 0.04);
}

.card.tiers-mobile-accordion .nested {
  margin: 0;
  border: none;
  border-top: 1px solid #2D3449;
  border-radius: 0;
  backdrop-filter: none;
  background-color: transparent;
}

.card.tiers-mobile-accordion .card-header {
  border: 0;
  padding: 0;
}

.card.tiers-mobile-accordion .btn {
  display: flex;
  align-self: center;
  justify-content: space-between;
  width: 100% !important;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
  color: var(--color-text-white);
  border-color: transparent;
  align-items: center;
  padding: 28px 30px;
}

.card.tiers-mobile-accordion .btn {
  &.active,
  &:active {
    color: var(--color-text-violet);
    border-color: transparent;
  }
}

.card.tiers-mobile-accordion .nested .btn {
  &.active,
  &:active {
    color: var(--color-text-white);
    border-color: transparent;
  }
}

.card.tiers-mobile-accordion .btn.active .arrow {
  transform: rotate(180deg);
}

.card.tiers-mobile-accordion .nested .btn .arrow {
  transform: rotate(-90deg);
}

.card.tiers-mobile-accordion .nested .btn.active .arrow {
  transform: rotate(0deg);
}

.card.tiers-mobile-accordion .btn .arrow svg {
  width: 15px;
  height: 15px;
}

.card.tiers-mobile-accordion .nested .btn {
  padding: 15px 30px;
  border-color: transparent;
}

.card.tiers-mobile-accordion .btn span {
  transition: 0.3s linear;
}

.card.tiers-mobile-accordion .card-body {
  width: 100%;
  padding: 0;
  border: none;
  background: #0B0B0F;
  border-bottom-left-radius: 26px;
  border-bottom-right-radius: 26px;
}

.card.tiers-mobile-accordion .card-body.fcfs {
  background: transparent;
  border-bottom-left-radius: 26px;
  border-bottom-right-radius: 26px;
}

.card.tiers-mobile-accordion.spark .card-body {
  background: #0B0B0F;
  border-radius: 0;
}

.card.tiers-mobile-accordion.smoke .card-body {
  background: #0B0B0F;
  border-radius: 0;
}

.card.tiers-mobile-accordion.fire .card-body {
  background: #0B0B0F;
  border-radius: 0;
}

.accordion-section {
  width: 100%;
  border-top: 1px solid #2D3449;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 11px 25px;
}

.accordion-section.guaranteed {
  align-items: center;
}

.accordion-section.lottery .accordion-section-data {
  font-size: 24px;
  font-weight: bold;
  align-items: center;
}

.accordion-section.lucky {
  padding-bottom: 15px;
}

.accordion-section.lottery {
  padding-bottom: 15px;
}

.accordion-section.cooldown {
  padding-bottom: 15px;
}

.accordion-section.guaranteed .accordion-section-data span {
  font-size: 16px;
  padding-bottom: 15px;
}

.accordion-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion-header {
  &.spark img, &.flame img, &.fire img, &.smoke img {
    height: 40px;
    width: 40px;
    margin-right: 5px;
  }
}

.accordion-header-title {
  margin-right: 10px;
}


.accordion-section-title {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.accordion-section-title .tooltip-wrapper {
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
  margin-left: 5px;
}

.accordion-section-header {
  display: flex;
  align-items: center;
  border-top: 1px solid #2D3449;
  padding: 13px;
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.accordion-section-header.light img {
  height: 40px;
  width: 40px;
  margin-right: 5px;
}

.accordion-section-header img {
  height: 40px;
  width: 40px;
  margin-right: 5px;
}

.accordion-section-data {
  display: flex;
  align-items: flex-end;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding-top: 9px;
}

.accordion-section-data.power {
  font-size: 24px;
}

.accordion-section-data.multiple {
  font-size: 24px;

  &.flame, &.fire, &.smoke {
    display: block;
    font-weight: bold;
    width: 40px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: var(--color-main-gradient-text);
    font-size: 32px;
    padding: 0;
    transform: rotate(-25deg);
  }
}

.accordion-section-data img {
  width: 40px;
  height: 40px;
  margin-right: 15px;;
}

.accordion-section-data .purple-text {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.5px;
}

@import "src/styles/mixins";

.project-progress {
  width: 100%;

  &__values {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    font-weight: 600;
    line-height: 1.26;
    letter-spacing: 1px;

    big {
      font-size: 32px;

      @include media-breakpoint-down(sm) {
        display: block;
        font-size: 24px;
      }
    }
  }

  .progress {
    width: 100%;
    background: rgba(255, 255, 255, 0.2);
    overflow: unset;
    backdrop-filter: blur(20px);
    border-radius: 10px;
  }

  .progress-bar {
    background: linear-gradient(89.94deg, #6E2791 0.05%, #008BFF 99.95%);
    box-shadow: 0px 0px 20px rgba(61, 84, 194, 0.5);
    border-radius: 10px;
  }
}

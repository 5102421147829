@import "src/styles/mixins";

.lottery-result-modal {
  .modal-dialog {
    max-width: 604px;
  }
}


.lottery-result {
  &.modal-content {
    background-color: #15171C;
  }

  .modal-body {
    padding: 0;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: calc(var(--tile-padding) / 2);
    top: calc(var(--tile-padding) / 2);
    width: 24px;
    height: 24px;

    path {
      transition: 0.15s linear;
    }

    &:hover {
      path {
        fill: var(--color-text-white-transparantize);
      }
    }
  }

  &__banner {
    width: calc(100% + var(--tile-padding) * 2);
    margin: 0 -40px 32px;
    mix-blend-mode: screen;
  }

  &__message {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.26;
    margin-bottom: 32px;

    @include media-breakpoint-down(lg) {
      font-size: 24px;
    }
  }

  &__buttons {
    display: flex;
    gap: 16px;

    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;
    }

    .round-button {
      flex-grow: 1;
      white-space: nowrap;
    }
  }
}

#confetti-canvas {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  pointer-events: none;
}

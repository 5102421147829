@import "src/styles/mixins";

.imo-details {
  .info-list {
    &__item {
      padding: 20px 0;
      line-height: 40px;
      margin: 0;

      &:not(:last-child) {
        border-bottom: 1px solid var(--color-main-grey);
      }

      &:first-child {
        padding-top: 0px;
      }

      &:last-child {
        padding-bottom: 0px;
      }

      @include media-breakpoint-down(md) {
        line-height: 1.26;
        padding: 18px 0;
        gap: 8px;
      }
    }
  }
}

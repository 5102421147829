.subscription-form__image {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -50px;
    filter: drop-shadow(0px 4px 20px rgba(255, 162, 88, 0.35));
}

.subscription-form {
    max-width: 604px;
}

.subscription-form__description {
    padding: 0 35px;
    margin-bottom: 24px;
}

.subscription-form__submit-button {
    width: 100%;
}

.subscription-form__submit-button .loader {
    vertical-align: middle;
}

@import "src/styles/mixins";

.kyc-page {
  padding-bottom: 20px;

  .container {
    max-width: 875px;
  }

  &.marketing-page.page h1.title {
    margin: 0;
    display: flex;
    justify-content: flex-start;

    @include media-breakpoint-down(sm) {
      font-size: 32px;
      line-height: 126%;
      font-weight: 600;
      letter-spacing: .5px;
    }
  }
}

.kyc-page__caption {
  margin-bottom: 15px;
}

.kyc-page__caption p {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  letter-spacing: .5px;

  @include media-breakpoint-down(md) {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    letter-spacing: .5px;
  }
}

.kyc-page__requirements h3.title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: .5px;
}

.kyc-page__requirements ul {
  margin-bottom: 40px;
}

.kyc-page__requirements li {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  letter-spacing: .5px;
  color: var(--color-text-violet);

  @include media-breakpoint-down(md) {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    letter-spacing: .5px;
  }
}

.kyc-page__requirements .kyc-badge__status {
  font-size: 32px;

  @include media-breakpoint-down(md) {
    font-size: 16px;
    line-height: 126%;

  }
}

.kyc-page__requirements .kyc-badge__message {
  @include media-breakpoint-down(md) {
    font-size: 16px;
    line-height: 126%;

  }
}

.kyc-page__requirements .row {
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

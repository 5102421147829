.saft-modal {
  padding: 0 !important;
}

.saft-modal__dialog {
  min-height: unset;
  height: 85vh;
}

.modal-content.saft-modal__content {
  padding: 32px;

  @media (max-width: 500px) {
    padding: 0;
  }
}

.modal-header.saft-modal__header {
  justify-content: flex-start;
  border: none;
  margin-bottom: 32px;

  @media (max-width: 500px) {
    margin-bottom: 10px;
  }
}

.saft-modal__project-logo {
  width: 140px;
  height: 140px;
  margin-right: 24px;

  @media (max-height: 850px) {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 1199px) {
  .saft-modal__body h2.title {
    font-size: 32px;
  }
}

@media (max-width: 500px) {
  .saft-modal__body h2.title {
    font-size: 20px;
  }
}

.saft-modal__project-name {
  font-size: 40px;
  font-weight: 600;
  line-height: 1.26;
  margin: 0;

  @media (max-width: 500px) {
    font-size: 25px;
  }
}

.saft-content {
  margin-top: 32px;
  height: 32vh;
  padding-right: 20px;
  max-height: 583px;
}

.saft-content ol {
  padding: 1rem;
}

.saft-content h2 {
  font-size: 28px;
  margin-bottom: 18px;
}

.modal-footer.saft-modal__footer {
  border: none;
  flex-direction: column;
}

.saft-modal__footer .form-group {
  margin: 0;
}

.saft-modal__footer > div {
  margin: 10px 0;
}

.saft-modal__footer .round-button.transparent {
  background: none;
  text-decoration: underline;
  text-transform: none;
  backdrop-filter: none;
  border-color: transparent;
}

.round-button.saft-modal__continue {
  width: 400px;

  @media (max-width: 500px) {
    width: 250px;
  }
}

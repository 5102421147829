@import 'src/styles/mixins';

.wrong-network-modal .modal-dialog {
  max-width: 610px;
}

.wrong-network-modal .modal-content {
  padding: 40px 54px;

  @include media-breakpoint-down(lg) {
    padding: 30px 20px;
  }
}

.wrong-network-modal .modal-title {
  font-size: 40px;
  font-weight: 600;
  line-height: 1.26;
  margin-bottom: 24px;

  @include media-breakpoint-down(lg) {
    font-size: 28px;
  }
}

.wrong-network-modal p {
  line-height: 1.5;
  margin-bottom: 12px;
  letter-spacing: 1px;
}

.wrong-network-modal__buttons {
  display: flex;
  justify-content: center;
  margin-top: 40px;

  @include gap(16px);
}

@import 'src/styles/mixins';

.project-widget {
  transition: .2s linear;
  height: 100%;
  border: 1px solid transparent;
  cursor: pointer;

  &:hover {
    background-color: #23282F;
    border-color: var(--project-main-color, var(--color-text-violet));
  }

  &.tile {
    padding: 8px;
    border-radius: 24px;
  }

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .project-tag--status {
      position: absolute;
      top: 8px;
      left: 8px;

      @include media-breakpoint-down(sm) {
        top: 16px;
        left: 16px;
      }
    }
  }

  &__background {
    width: 100%;
    position: relative;
    margin-bottom: 13%;

    @include media-breakpoint-down(xs) {
      position: unset;
      display: flex;
      margin-bottom: unset;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__background-image {
    width: 100%;
    border-radius: 16px;

    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  &__background-logo {
    position: absolute;
    width: 25%;
    top: 100%;
    right: 50%;
    transform: translateX(50%) translateY(-50%);

    @include media-breakpoint-down(xs) {
      position: unset;
      transform: unset;
      margin-top: 50px;
    }
  }

  &__name {
    font-weight: 600;
    font-size: 24px;
    margin-top: 8px;
    margin-bottom: 15px;
  }

  &__info {
    margin-top: 30px;
    margin-bottom: 24px;
    width: 90%;

    & .name, & .value {
      font-size: 16px;

      &.goal {
        font-size: 18px;
      }
    }
  }

  .project-social-links {
    justify-content: center;
    width: 100%;
    margin-bottom: 15px;
    @include gap(8px);

    a {
      width: 40px;
      height: 40px;

      svg {
        width: 22px;
        height: 22px;
      }
    }

    @include media-breakpoint-down(sm) {
      gap: 16px;
    }
  }
}

.project-tags {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  @include gap(8px);

  .project-tag {
    line-height: 24px;

    @include media-breakpoint-down(lg) {
      font-size: 14px;
    }
  }
}

@import "src/styles/mixins";

$item-padding: 20px;

.bb-nfts-list {
  margin: auto;
  --bb-nfts-columns: repeat(3, 2fr);

  @include media-breakpoint-down(lg) {
    --bb-nfts-columns: 1fr;
  }

  &__heading {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: var(--bb-nfts-columns);
    padding: 0 var(--tile-padding);
    align-items: center;
    color: var(--color-text-white-transparantize);
    letter-spacing: 1px;

    @include media-breakpoint-down(lg) {
      display: none;
    }

    div {
      display: flex;
      padding: 16px 0;

      &:not(:last-child, :first-child) {
        justify-content: center;
      }
    }
  }

  &__body {
    @include media-breakpoint-down(lg) {
      margin-top: 24px;
    }
  }

  &__item:not(:last-child) {
    margin-bottom: 16px;
  }
}

.bb-nfts-item {
  display: grid;
  grid-template-columns: var(--bb-nfts-columns);
  grid-gap: 12px;
  align-items: center;

  @include media-breakpoint-down(lg) {
    gap: 16px;
  }

  &__col {
    display: flex;
    align-items: center;
    font-size: 24px;
    letter-spacing: 1px;
    gap: 12px;

    span:first-child {
      color: var(--color-text-white-transparantize);
    }

    @include media-breakpoint-down(lg) {
      justify-content: space-between;
      font-size: 16px;
      gap: 8px;
    }

    @include media-breakpoint-up(lg) {
      &:not(:last-child, :first-child) {
        justify-content: center;
      }

      span:first-child {
        display: none;
      }
    }

    &--utility {
      font-size: 16px;

      @include media-breakpoint-down(lg) {
        flex-wrap: wrap;
      }
    }
  }

  .loader {
    --loader-size: 1em;
  }
}
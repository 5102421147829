@import "src/styles/mixins";

.account-lockup {
  padding-top: 45px;

  @include media-breakpoint-down(lg) {
    padding-top: 24px;
  }

  .balances {
    margin-top: 25px;

    .balance-item.top-item {
      padding-top: 0;
      padding-bottom: 16px;
      border-bottom: 1px solid var(--color-main-grey);
    }

    .balance-item.bottom-item {
      padding-bottom: 0;
    }
  }
}

.stake-block {
  margin: 5px;
}

.stake-block__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.stake-block__balance {
  @include media-breakpoint-down(md) {
    margin-bottom: 5px;
  }
}

.account-lockup .stake-form {
  height: 380px;

  @include media-breakpoint-down(lg) {
    height: 300px;
  }

  @include media-breakpoint-down(md) {
    margin-top: 24px;
  }

  .stake-block-tabs {
    .nav-link.nav-item {
      width: 50%;
    }
  }
}

.penalties-section {
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 1px;
  padding-top: 120px;
  padding-bottom: 120px;

  @include media-breakpoint-down(lg) {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.penalties-section .stats-title {
  @include media-breakpoint-down(lg) {
    font-size: 24px;
  }
}

.penalties-row {
  margin-bottom: 140px;

  @include media-breakpoint-down(lg) {
    margin-bottom: 12px;
  }
}

.account-page .penalties-row .title {
  margin-bottom: 1rem;

  @include media-breakpoint-down(lg) {
    font-size: 24px;
  }
}

.account-page .penalties-row .main {
  max-width: 414px;
  @include media-breakpoint-down(lg) {
    font-size: 16px;
    line-height: 126%;
  }
}

.account-page .penalties-row span {
  font-weight: 700;
  color: var(--color-text-violet);
}

.account-page .penalties-row .layer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(90deg, #32363C 7.56%, #212326 93.56%);
  height: 90px;
  border-radius: 30px;
  padding: 0 36px;
  margin-bottom: 23px;

  @include media-breakpoint-down(lg) {
    margin-bottom: 12px;
  }
}

.account-page .penalties-row .layer > div {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 46px;
  line-height: 126%;
}

.account-page .penalties-row .layer > div span {
  color: #fff;
  font-size: 26px;
}

.account-page .penalties-row .layer6 {
  color: #46FEA6;
}

.penalties-section .ellipse21 {
  left: 0;
  top: 49%;
}

.account-page .heat-row {
  margin: 40px 0 0;
}

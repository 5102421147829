@import 'src/styles/mixins';

.nfts-collection {
  padding-top: 120px;
  padding-bottom: 120px;

  @include media-breakpoint-down(md) {
    padding-top: 32px;
    padding-bottom: 56px;
  }

  & > .container > .loader {
    flex-basis: 392px;
    margin: auto;
  }

  .no-projects-tile {
    height: unset;
    text-align: left;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;

    $points: (
      xxs: (gap: 16px, items: 1,),
      md: (gap: 24px, items: 2,),
      lg: (gap: 32px, items: 2,),
      xl: (gap: 32px, items: 3,)
    );

    @each $bp, $settings in $points {
      @include media-breakpoint-up($bp) {
        $gap: map-get($settings, 'gap');
        $items: map-get($settings, 'items');
        @include gap($gap);

        .nft-card {
          $width: calc((100% - #{$items - 1} * #{$gap}) / $items);
          flex-basis: $width;
          width: $width;
        }
      }
    }
  }

  .nft-card {
    @include media-breakpoint-down(md) {
      max-width: 322px;
      margin: 0 auto;
    }

    @include media-breakpoint-down(sm) {
      max-width: 289px;
    }
  }
}


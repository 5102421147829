@import 'src/styles/mixins';

.mobile-heat-row {
  .color-strip {
    background: rgba(35, 40, 47, 0.9);
    width: 15px;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    position: relative;
  }

  .color-strip__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top-right-radius: 100px;
    border-top-left-radius: 100px;
    background: linear-gradient(108.68deg, #C4A2FF 4.9%, #FF9393 74.14%);
  }

  .pills {
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding: 0;
    flex-direction: column;
  }

  .pill {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 20%;
  }

  .arrow {
    margin-left: 10px;
  }

  .progress {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: unset;
  }

  .progress .item {
    margin-top: 5px;
    margin-bottom: 5px;
    background: #15171C;
    backdrop-filter: blur(80px);
    border-radius: 24px;
    padding: 5px;
    display: flex;
    height: 20%;
  }

  .progress .item .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 126%;
    margin-bottom: 5px;
  }

  .progress .item .subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 126%;
  }

  .progress .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

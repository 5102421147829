@import 'src/styles/mixins';

.buy-flame-dropdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.buy-flame-dropdown .dropdown-toggle {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  @include blurred-dark-background(40px);
  color: var(--color-text-white);
  border: none;
  border-radius: 30px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 2px 30px;
  font-size: 16px;
}

.buy-flame-dropdown.show .btn-primary.dropdown-toggle,
.buy-flame-dropdown .btn-primary.dropdown-toggle:hover,
.buy-flame-dropdown .btn-primary.dropdown-toggle:focus,
.buy-flame-dropdown .btn-primary.dropdown-toggle:active {
  @include blurred-dark-background(40px);
  color: var(--color-text-white);
  border: none;
}

.buy-flame-dropdown .dropdown-menu {
  inset: 10px auto auto 0 !important;
  min-width: 100%;
  overflow: hidden;
  @include blurred-dark-background(40px, relative, 0.06);
  border-radius: 16px;
  z-index: 2;
  font-size: 16px;
}

.buy-flame-dropdown__item img {
  height: 32px;
  width: 32px;
  margin-right: 10px;
}

.buy-flame-dropdown__item.dropdown-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  color: var(--color-text-white);
  padding: 0.5rem 1rem;
}

.buy-flame-dropdown__item.dropdown-item:hover,
.buy-flame-dropdown__item.dropdown-item.active,
.buy-flame-dropdown__item.dropdown-item:active {
  background-color: transparent;
  color: var(--color-text-violet);
}

.buy-flame-dropdown .dropdown-toggle::after {
  transition: 0.3s linear;
  border: none;
  margin-left: 12px;
  width: 12px;
  height: 7px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('../../../assets/arrow.svg');
  color: black;
}

.buy-flame-dropdown .dropdown-toggle::after img path {
  fill: var(--color-text-violet);
}

.buy-flame-dropdown.show .dropdown-toggle::after {
  transform: rotate(180deg);
}

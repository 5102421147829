@import "src/styles/mixins";

.token-address {
  padding: 11px 12px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 50px;

  &, .copiable {
    display: inline-flex;
    align-items: center;
    @include gap(10px);
  }

  &__address {
    font-weight: 600;
    letter-spacing: 1px;
  }
}

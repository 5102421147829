.swap-form {
  border-radius: 24px;
  padding: 46px;

  .form-message {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .info-list {
    margin-bottom: 16px;

    big {
      font-size: 1.5em;
    }
  }

  .input-group {
    margin-bottom: 24px;
  }

  .input-group img {
    width: 32px;
  }

  &__buttons {
    display: flex;
    width: 100%;
    margin-bottom: 24px;
    gap: 16px;

    @media (max-width: 500px) {
      flex-wrap: wrap;
    }

    .round-button {
      flex-basis: 50%;
      flex-grow: 1;

      @media (max-width: 500px) {
        flex-basis: 100%;
      }
    }

    svg path {
      fill: currentColor;
    }
  }
}

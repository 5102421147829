@import 'src/styles/mixins';

.tooltip-wrapper {
    cursor: pointer;
}

.common-tooltip .tooltip-inner {
    @include blurred-dark-background(260px);
    border-radius: 24px;
    padding: 24px;
    max-width: 450px;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
}

.common-tooltip.tooltip.show {
    opacity: 1;
}

.common-tooltip .tooltip-inner {
  @include blurred-dark-background(200px, relative, .1);
  border-radius: 16px;
}

.tooltip__title {
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 1.12;
  font-weight: 600;
  margin-bottom: 16px;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

.tooltip__title img {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.tooltip__content p {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;
}

.tooltip__button {
  width: 100%;
  margin-top: 24px;
}

.tooltip__widget {
  background: rgba(11, 11, 15, 0.4);
  border: 1px solid #2D3449;
  backdrop-filter: blur(80px);
  border-radius: 16px;
  padding: 16px;
  margin-top: 12px;
  text-align: left;
}

.tooltip__widget .purple-text {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.5px;
}

.tooltip__expression {
  margin-bottom: 8px;
}

.tooltip__expression b {
  margin: 0 6px;
}

.tooltip__expression span {
  width: 24px;
  height: 24px;

  svg path {
    fill: white;
  }
}

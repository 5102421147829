.cms-media-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 24px 0;
}

.cms-media {
  width: 100%;
  height: auto;
  border-radius: 24px;
}

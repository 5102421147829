@import "src/styles/mixins";

.blind-boxes-lottery {
  padding-top: 45px;
}

.lottery-section {
  padding-bottom: 48px;
}

.lottery-block {
  &.row {
    position: relative;
    margin-bottom: 86px;
    align-items: stretch;
    --bs-gutter-y: var(--bs-gutter-x);
  }

  &__balance {
    padding-left: 44px;

    .tile-referral {
      display: flex;
      flex-direction: row;
      align-items: center;

      &__caption {
        font-size: 20px;
        font-weight: 600;
        margin-right: 30px;
      }
    }
  }

  &__rules {
    border-radius: 24px;
    padding: 14px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;

    span {
      font-weight: 500;
      line-height: 1.4;
      letter-spacing: 1px;
      max-width: 160px;
    }

    .round-button {
      flex-shrink: 0;
    }
  }

  .balance-item {
    &__title {
      max-width: unset;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--color-separator);
    }

    &--referral {
      .tile__description {
        color: var(--color-text-white);
      }
    }
  }
}

.lottery-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &--results {
    padding-right: 16px;
  }

  &.hidden {
    display: none;
  }

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    @include media-breakpoint-down(xl) {
      flex-wrap: wrap;
    }

    .round-button {
      display: inline-flex;
      align-items: center;
      line-height: normal;
      padding: 12px 16px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__input-icon {
    height: 48px;
  }

  .input-group {
    margin-bottom: 26px;
  }

  .form-message {
    letter-spacing: 1px;

    .loader {
      --loader-size: 1.25em;
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
    }
  }

  &__buttons {
    display: flex;
    width: 100%;
    margin: auto 0 5px;
    @include gap(16px);

    .btn.round-button {
      flex-basis: 50%;
      flex-grow: 1;
      margin: 0;
    }

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;

      .round-button {
        flex-basis: 100%;
      }
    }
  }

  .form-message {
    margin-bottom: 17px;
    min-height: 2.5em;
  }
}

@media (max-width: 1199px) {
  .betting-form {
    padding: 24px;  }
}

.form-disclaimer {
  margin-top: 24px;

  &__divider {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 4px;

    .divider {
      flex-grow: 1;
    }
  }

  &__text {
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    line-height: 1.4;
    letter-spacing: 1px;
    margin-bottom: 0;
    padding: 0 30px;
  }
}

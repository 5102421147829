@import "src/styles/mixins";

.project-hero {
  width: 100%;

  @include media-breakpoint-down(md) {
    text-align: center;
  }

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 21px;
    gap: 16px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      gap: 8px;
    }
  }

  &__logo {
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: center;

    @include media-breakpoint-down(md) {
      width: 80px;
      height: 80px;
    }
  }

  &__head-row {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include media-breakpoint-down(md) {
      gap: 16px;
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  &__name {
    font-size: 40px;
    font-weight: 600;
    line-height: 1.26;
    margin-bottom: 0;

    @include media-breakpoint-down(md) {
      font-size: 24px;
    }
  }

  main {
    margin-bottom: 24px;
  }

  &__banner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__banner-image {
    max-width: 100%;
    height: auto;
    border-radius: 16px;
  }

  &__download-terms {
    margin-bottom: 24px;
  }

  @include media-breakpoint-down(md) {
    .project-social-links {
      justify-content: center;
    }
  }
}

@import "src/styles/mixins";

.whitelist-form {
  .title {
    margin-bottom: 32px;
  }

  .round-button {
    margin: 0;
  }

  .form-row {
    justify-content: space-between;

    &:not(.form-row + .form-row) {
      margin-top: 40px;
    }

    @media (max-width: 550px) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    .round-button {
      width: 216px;
      padding: 6px 0;
      flex-shrink: 0;

      @include media-breakpoint-down(sm) {
        margin-top: 20px;
        width: 100%;
      }
    }
  }

  .form-check {
    margin-right: 12px;
  }

  .input-group {
    padding: 16px;

    @include media-breakpoint-down(sm) {
      padding: 10px;
    }

    &.is-invalid {
      border-color: #dc3545;
      padding-right: calc(1.5em + .75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(.375em + .1875rem) center;
      background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    }

    .form-control {
      text-align: left;
      font-size: 16px;

      @include media-breakpoint-down(sm) {
        font-size: 14px;
      }
    }
  }

  .input-group-prepend {
    margin-right: 2px;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }

  .round-button[type="submit"] {
    width: 100%;
    margin-top: 16px;

    svg {
      margin-top: -2px;

      path {
        fill: var(--color-text-black);
      }
    }
  }

  .form-check-label {
    font-size: 16px;

    @include media-breakpoint-between(md, xxl) {
      font-size: 14px;
    }
  }
}

@import "src/styles/mixins";

.loot-boxes-list {
  --boxes-columns: 2.5fr 3fr repeat(3, 3.5fr) 4fr;

  @include media-breakpoint-down(lg) {
    --boxes-columns: 1fr;
  }

  .no-projects-tile.hidden {
    display: none;
  }

  &__body {
    margin-top: 24px;

    & > .loader {
      display: block;
      margin: 35px auto 0;
    }

    &:empty + .no-projects-tile.hidden {
      display: flex;
    }
  }

  &__header {
    list-style: none;
    padding: 16px 40px;
    margin: 0;
    display: grid;
    grid-gap: 18px;
    grid-template-columns: var(--boxes-columns);

    @include media-breakpoint-down(lg) {
      display: none;
    }

    li {
      display: flex;
      align-items: center;
      gap: 16px;
      line-height: 1.26;
      letter-spacing: 1px;
      color: var(--color-text-white-transparantize);

      &:not(:first-child) {
        justify-content: center;
      }
    }
  }

  .loot-box {
    margin-bottom: 24px;
  }
}
@import "src/styles/mixins";

.add-polygon-tutorial {
  height: unset;

  .logo {
    height: 100px;
    width: 100px;
    padding: 0;
    margin-bottom: 20px;
  }
}

.add-polygon-tutorial .tile__description.first {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: .5px;
  color: var(--color-text-white);
  max-width: 417px;
  text-align: center;
}

.description-container {
  border: 1px solid var(--color-main-grey);
  border-radius: 24px;
  padding: 24px;
  margin-bottom: 20px;

  .tile__description {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: .5px;
  }

  .tile__description.last {
    margin-bottom: 0;
  }
}

.marketing-page.page h1.title {
  margin: 0;
  display: flex;
  justify-content: center;

  @include media-breakpoint-down(sm) {
    font-size: 32px;
    line-height: 126%;
    font-weight: 600;
    letter-spacing: .5px;
  }
}

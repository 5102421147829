.cms-text-node {
  &.cms-bold {
    font-weight: 700;
  }
  &.cms-italic {
    font-style: italic;
  }
  &.cms-underline {
    text-decoration: underline;
  }
  &.cms-highlighted {
    font-weight: 700;
    color: var(--color-text-violet);
  }
}

@import 'src/styles/mixins';

.nfts-page {
  .top-banner {
    height: calc(100vh - 260px);
    min-height: 670px;

    @include media-breakpoint-down(md) {
      height: 425px;
      min-height: unset;
    }
  }

  .hero-row {
    align-content: center;
    padding-bottom: 0;

    .subtitle {
      @include media-breakpoint-down(md) {
        font-size: 24px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 16px;
      }
    }
  }

  .hero-row .hero-row__main {
    max-width: unset;
    margin: 0 auto min(11vw, 12vh);

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 140%;
      border-radius: 75%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--color-background);
      filter: blur(60px);
      z-index: 1;
    }

    @include media-breakpoint-down(md) {
      margin: 0;
    }

    @include media-breakpoint-down(sm) {
      max-width: 70%;
      margin: 0 auto;
    }
  }

  &-navbar {
    max-width: 448px;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-down(md) {
      max-width: 330px;
    }
  }
}

.hero-row__main p {
  position: relative;
  z-index: 1;
}

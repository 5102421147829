@import "src/styles/mixins";

.card.footer-accordion {
  width: 100%;
  text-align: center;
  color: var(--color-text-white-transparantize);
  border: none;
  background-color: transparent;
}

.card.footer-accordion .card-header {
  border: none;
  padding: 0;
  background-color: transparent;
}

.card.footer-accordion .btn {
  display: flex;
  align-self: center;
  justify-content: space-between;
  width: 100% !important;
  height: 100% !important;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
  color: var(--color-text-white);
  align-items: center;
  padding: 17px 6px 18px 0;
  border-radius: unset;
}

.card.footer-accordion .btn:not(.active) {
  border-bottom: 1px solid rgb(255, 255, 255, 0.2);
}

.card.footer-accordion .btn.active {
  font-weight: 500;
  color: var(--color-text-violet);
}

.card.footer-accordion .btn.active .arrow {
  transform: rotate(180deg);
}

.card.footer-accordion .btn .arrow svg {
  width: 12px;
  height: 7px;
}

.card.footer-accordion .btn span {
  transition: 0.3s linear;
}

.card.footer-accordion .collapse.show {
  font-weight: 500;
  border-bottom: 1px solid rgb(255, 255, 255, 0.2);
}

.card.footer-accordion .card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  border: none;
  background: transparent;
  font-size: 24px;
  font-weight: normal;

  .custom-link {
    padding: 17px 0;
    display: flex;
    align-items: flex-start;
    text-transform: unset;
    font-size: 16px;
    font-weight: normal;
  }

  > a {
    width: 100%;
  }
}

.mobile-footer__col {
  margin-top: 35px;

  .footer__col-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include gap(0);

    .footer__socials {
      order: 3;
      align-items: flex-start;
      justify-content: center;
      margin-bottom: 24px;
    }

    .btn {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 35px;
    }

    .footer__title {
      font-size: 16px;
      text-align: center;
    }
  }
}

.mobile-footer-copyrights {
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 16px;
    margin-bottom: 24px;
    color: rgba(255, 255, 255, 0.5);
  }

  .quantstamp-link {
    margin-bottom: 16px;
  }

  .quantstamp-marks {
    display: flex;
    @include gap(12px);
  }
}

.mobile-footer .firestarter-link {
  height: 40px;
  margin-bottom: 24px;
}

@import 'src/styles/mixins';

.get-ready-badge.tile--with-shadow {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('../../../assets/get-incubated-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 32px;
}

.get-ready-badge .tile__main {
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 40px;
  text-align: center;

  @include media-breakpoint-down(sm) {
    font-size: 32px;
  }
}

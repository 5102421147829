@import "src/styles/mixins";

.project-page {
  padding-top: 70px;

  @include media-breakpoint-down(md) {
    padding-top: 16px;
  }
}

.project-page > .loader {
  display: block;
  margin: 0 auto;
}

.project-grid {
  display: grid;
  grid-template-areas:
    "top aside"
    "bottom aside"
    "bottom aside";
  grid-template-columns: auto 520px;
  grid-auto-rows: min-content auto auto;
  grid-gap: 32px;

  @include media-breakpoint-down(xl) {
    grid-template-columns: auto 450px;
  }

  @include media-breakpoint-down(lg) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "top top"
      "aside aside"
      "bottom bottom";
  }

  &__top {
    grid-area: top;
  }

  &__aside {
    grid-area: aside;
  }

  &__bottom {
    grid-area: bottom;
  }
}

.presale-section {
  > *:not(:first-child) {
    margin-top: 32px;
  }
}

.details-section .ellipse19 {
  top: 20px;
  left: 80%;
}

.details-section__warning-message {
  letter-spacing: 0.5px;
  text-align: center;
  color: var(--color-text-red);
}

.swap-section {
  margin-top: 80px;
  padding-bottom: 50px;
}

.swap-section .ellipse18 {
  width: 220px;
  height: 220px;
  left: 3%;
  top: -50%;
}

.swap-section .tile {
  padding: 40px;
  @media (max-width: 500px) {
    padding: 20px;
  }
}

.project-page .whitelist-block {
  margin-top: 40px;
}

.project-page .whitelist-block p {
  max-width: 370px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 1px;
}

.project-page .whitelist-block a:not(.round-button) {
  color: inherit;
}

.project-page .whitelist-block a:not(.round-button):hover {
  color: var(--color-text-white);
}

.project-page__wallet-info {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.external-description__nft-image {
  width: 100%;
}

.external-description__nft-image img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  object-position: center;
  border-radius: 24px;
}

@media (max-width: 1199px) {
  .external-description__nft-image img {
    max-height: 600px;
  }
}

.external-description__nft-subtitle {
  font-size: 40px;
  font-weight: 600;
}

.claim-section {
  margin-top: 80px;
}

:is(.claim-section, .swap-section) .title {
  font-weight: 600;
  font-size: 32px;
  line-height: 126%;
  margin-bottom: 24px;
}

@media (max-width: 1199px) {
  .swap-form {
    padding: 24px;
  }
}

.external-presale-navigation-block {
  margin: 15px 0;
  display: inline-flex;
  flex-direction: column;
  gap: 15px;

  &__title {
    font-size: 22px;
  }

  a.btn {
    margin-top: 0 !important;
  }
}

.linking-message {
  padding: 10px;
  border-radius: 5px;
  margin-top: 12px; /* equivalent to mt-3 if your margin scale is 4px per step */
  text-align: center;
}

/* Specific styling for warning messages */
.linking-message--warning {
  background-color: rgba(255, 250, 230, 0.91); /* light yellow background */
  border: 1px solid #ffd700; /* golden border */
  color: #806600; /* dark yellow text for better visibility */
}


$screen-xxs: 320px;
$screen-xs: 460px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1400px;

$grid-breakpoints: (
        xxs: $screen-xxs,
        xs: $screen-xs,
        sm: $screen-sm,
        md: $screen-md,
        lg: $screen-lg,
        xl: $screen-xl,
        xxl: $screen-xxl,
);

:root {
  --color-text-white: #FFFFFF;
  --color-text-black: #181A1B;
  --color-text-white-transparantize: rgba(255, 255, 255, 0.5);
  --color-bg-white-transparantize: rgba(255, 255, 255, 0.1);
  --color-background: #0B0B0F;
  --color-text-red: #FE4646;
  --color-bg-red-transparantize: rgba(254, 70, 70, 0.1);
  --color-text-yellow: #FFBB55;
  --color-text-success: #02C88D;
  --color-text-violet: #C58BFF;
  --color-bg-violet-transparentize: rgba(197, 139, 255, 0.1);
  --color-lottery-bg: #15171c;
  --color-lottery-bg-transparent: rgba(21,23,28,0.93);
  --color-main-grey: #3A4259;
  --color-main-dark: #15171C;
  --color-main-gradient-text: linear-gradient(108.68deg, #C4A2FF 4.9%, #FF9393 74.14%);
  --color-warning-bg: rgba(234, 68, 98, 0.2);
  --color-separator: #414347;
  --color-separator-transparentize: rgba(65, 67, 71, 0.5);
  --container-max-width: 100%;

  --tile-padding: 24px;

  --base-font: 'Archivo', sans-serif;

  --section-padding-bottom: 100px;
  --section-padding-top: 100px;
  --section-padding-mobile-top: 50px;
  --section-padding-mobile-bottom: 50px;
}

@media (min-width: 576px) {
  :root {
    --container-max-width: 540px;
  }
}

@media (min-width: 768px) {
  :root {
    --container-max-width: 720px;
  }
}

@media (min-width: 992px) {
  :root {
    --container-max-width: 960px;
    --tile-padding: 40px;
  }
}

@media (min-width: 1200px) {
  :root {
    --container-max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  :root {
    --container-max-width: 1320px;
  }
}

:root {
  --container-sticky-max-width: calc(var(--container-max-width) + ((100vw - var(--container-max-width)) / 2) - 12px)
}

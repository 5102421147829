@import "src/styles/mixins";

.lottery-results-list {
  --results-list-columns: 1.2fr 1.1fr 1fr;

  @include media-breakpoint-down(md) {
    --results-list-columns: 1fr;
  }

  &__heading {
    display: grid;
    grid-template-columns: var(--results-list-columns);
    grid-gap: 12px;
    align-items: center;
    color: var(--color-text-white-transparantize);
    letter-spacing: 0.5px;
    padding: 8px 6px 8px 0;

    div {
      display: flex;

      &:not(:first-child, :last-child) {
        justify-content: center;
      }
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__body {
    height: 381px;
    padding-right: 16px;

    ul {
      list-style: none;
      margin: 0;
      padding-left: 0;
    }

    .loader {
      margin: 20px auto;
    }
  }

  &__title {
    margin-top: 8px;
    color: var(--color-text-violet);
  }
}

.lottery-results-item {
  display: grid;
  grid-template-columns: var(--results-list-columns);
  grid-gap: 12px;
  padding: 20px 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-separator);
  }

  &__col {
    display: flex;

    & > span:first-child {
      color: var(--color-text-white-transparantize);

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    @include media-breakpoint-down(md) {
      justify-content: space-between;
      align-content: center;
    }

    @include media-breakpoint-up(md) {
      &:not(:first-child, :last-child) {
        justify-content: center;
      }
    }
  }
}
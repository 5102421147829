.tier-status {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  line-height: 1.26;
  letter-spacing: 1px;

  .loader {
    --loader-size: 24px;
  }

  .dynamic-image {
    width: 32px;
    height: 32px;
  }
}

@import "src/styles/mixins";

.leaderboard-page .subtitle, .leaders-section__caption {
  font-size: 24px;
  line-height: 1.5;
  letter-spacing: 0.5px;

  @include media-breakpoint-down(md) {
    font-size: 16px;
  }
}

.leaderboard-title {
  @include media-breakpoint-down(lg) {
    font-size: 32px !important;
  }
}

.leaderboard-page {
  .title-container {
    padding: 54px;

    @include media-breakpoint-down(lg) {
      padding: 20px;
    }
  }
}

.leaderboard-page__features-title {
  margin-bottom: 32px;
  display: flex;
  align-items: center;

  @include media-breakpoint-down(lg) {
    font-size: 24px !important;
  }
}

.leaderboard-page__features-title .round-button {
  margin-left: 16px;
  padding: 0;
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  @include media-breakpoint-down(lg) {
    width: 32px;
    height: 32px;
    margin-left: 8px;
    min-width: 32px;
  }

  img {
    @include media-breakpoint-down(lg) {
      width: 14px;
      height: 14px;
    }
  }
}

.leaderboard-page__features-title .round-button:hover {
  background: rgba(255, 255, 255, 0.35);
}

.leaderboard-page .features-section {
  margin-bottom: 80px;
}

.leaders-section__caption {
  margin-bottom: 32px;
}

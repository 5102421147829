@import 'src/styles/mixins';

.no-scroll {
  overflow: hidden;
}

.sticky-body {
  padding-top: 96px;
}

.header {
  transition: .3s linear all;
  position: relative;
  background: transparent;

  &.sticky {
    position: fixed !important;
    top: -96px;
    transform: translateY(96px);
    background-color: rgba(0, 0, 0, .4);
    backdrop-filter: blur(40px);
  }

  &.black {
    background-color: var(--color-background);
  }

  @include media-breakpoint-down(lg) {
    z-index: 110;
  }

  .navbar {
    background-color: transparent;
    padding: 15px;
  }

  .navbar-nav {
    align-items: center;
  }

  .navbar-brand {
    @include media-breakpoint-between(md, lg) {
      margin-right: auto;
    }

    .nav-link {
      padding: 0 !important;
    }

    img {
      @include media-breakpoint-down(xxl) {
        height: 50px;
      }

      @include media-breakpoint-down(xl) {
        height: 35px;
      }

      @include media-breakpoint-down(lg) {
        height: 45px;
      }

      @include media-breakpoint-down(sm) {
        height: 32px;
      }
    }
  }

  .round-button.btn {
    line-height: 38px;
  }

  .round-button.header__nav-button {
    border-radius: 50px;
    padding: 7px 25px;
    margin-right: 10px;

    &.transparent {
      padding: 8px 4px;
    }
  }

  .nav-dropdown {
    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .round-button {
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-down(lg) {
        background-color: transparent;
        font-size: 24px;
        font-weight: 500 !important;
        color: var(--color-text-white);
        margin: 0;

        &:hover {
          background-color: transparent;
          color: var(--color-text-white);
          border-color: transparent;
        }
      }

      &:after {
        transition: 0.3s linear;
        border: none;
        width: 12px;
        margin-left: 8px;
        height: 7px;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('../../../assets/arrow.svg');
      }

      &.light:after {
        background-image: url('../../../assets/arrow-black.svg');

        @include media-breakpoint-down(lg) {
          background-image: url('../../../assets/arrow.svg');
        }
      }
    }

    &.show .round-button::after {
      transform: rotate(180deg);
    }
  }
}

.account-dropdown {
  .round-button {
    &.header__nav-button {
      @include media-breakpoint-down(xxl) {
        padding: 10px 14px;
      }
    }

    svg {
      height: auto;
      margin: 0;
    }
  }
}

.blind-boxes-dropdown {
  .round-button.transparent {
    background: transparent;
    backdrop-filter: none;
  }
}

.nav-dropdown__menu.dropdown-menu {
  @include blurred-dark-background(200px, absolute);
  position: absolute;
  border-radius: 16px;
  min-width: 184px;
  top: calc(100% + 12px);
  z-index: 100;

  @include media-breakpoint-down(lg) {
    padding: 0;
    border: none;
    background: transparent;
  }
}

.dropup .nav-dropdown__menu.dropdown-menu {
  bottom: calc(100% + 12px);
  top: unset;
}

.nav-dropdown__item.dropdown-item {
  color: var(--color-text-white);
  padding: 0.65rem 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;

  &:not(.network-switcher__item) {
    @include media-breakpoint-down(lg) {
      padding-top: 0;
      text-transform: capitalize;
      display: flex;
      justify-content: center;
      font-size: 24px;

      span {
        margin-left: 5px;
      }
    }
  }
}

.nav-dropdown__item.dropdown-item:hover,
.nav-dropdown__item.dropdown-item.active,
.nav-dropdown__item.dropdown-item:active {
  background-color: inherit;
  color: var(--color-text-violet);
}

.network-switcher__dropdown .round-button.header__connect-wallet {
  display: contents;
}

.network-switcher__dropdown .btn.round-button::after {
  transition: 0.3s linear;
  border: none;
  width: 12px;
  height: 7px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('../../../assets/arrow.svg');
}

.network-switcher__dropdown.show .round-button::after {
  transform: rotate(180deg);
}

.header__connect-wallet span {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  text-transform: none;
  display: block;
  padding: 7px 25px;
  border-radius: inherit;

  @include media-breakpoint-down(xxl) {
    padding: 7px 14px;
  }
}

.navbar-collapse {
  @include gap(5px);
  justify-content: flex-end;

  @include media-breakpoint-down(lg) {
    @include gap(0)
  }
}

.header {
  .nav-link {
    padding: 0 !important;
    padding-bottom: 8px !important;
    margin-right: 15px;
    white-space: nowrap;
    position: relative;
    font-family: Archivo;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--color-text-white) !important;

    @include media-breakpoint-down(1160px) {
      font-size: 13px;
    }

    &.active:after {
      @include media-breakpoint-down(lg) {
        visibility: hidden;
      }
    }
  }
}

.header-links .nav-link {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}


.navbar-collapse.content {
  @include media-breakpoint-down(lg) {
    position: fixed;
    top: 85px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-background);
    width: 100%;
    height: calc(100vh - 85px);
    transition: 0.15s linear;

    .nav-link {
      font-size: 24px;
      padding: 18px !important;
      margin: 0;
    }

    &:not(.show) {
      visibility: hidden;
      opacity: 0;
      z-index: -100;
    }

    .show {
      visibility: visible;
      opacity: 1;
      z-index: 1000;
    }
  }

  @include media-breakpoint-down(sm) {
    height: calc(100vh - 72px);
    top: 72px;
  }
}

.header {
  .navbar {
    .navbar-toggler-icon {
      background-image: url("../../../assets/burger/close.svg");
      width: 45px;
      height: 45px;
      transition: 0.15s linear;

      @include media-breakpoint-down(sm) {
        width: 32px;
        height: 32px;
      }
    }
  }

  .collapsed .navbar-toggler-icon {
    background-image: url("../../../assets/burger/burger.svg");
  }

  .navbar-toggler {

    @include media-breakpoint-between(md, lg) {
      margin-left: 20px;
    }

    &,
    &:focus,
    &:active,
    &-icon:focus {
      padding: 0;
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
}


.mobile-wallet-panel {
  .wrapper {
    padding-top: 90px;
  }

  &__block {
    position: fixed;
    width: 100%;
    height: 90px;
    left: 0;
    bottom: 0;
    z-index: 90;
    padding-top: 16px;
    background: linear-gradient(179.11deg, rgba(11, 11, 15, 0.29) 0.76%, #0B0B0F 99.24%);
  }

  .network-switcher__dropdown {
    z-index: 90;
    background-color: var(--color-background);
  }
}

@import 'src/styles/mixins';

.balance-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;

  &.center-item {
    border-top: 1px solid var(--color-main-grey);
    border-bottom: 1px solid var(--color-main-grey);
  }

  &.top-item {
    padding-top: unset;
  }

  &.bottom-item {
    padding-bottom: unset;
  }

  .tooltip-wrapper {
    margin-left: 8px;
  }
}

.balance-item__icon {
  width: 48px;
  height: 48px;
  margin-right: 12px;

  &.dynamic-image svg {
    width: 100%;
    height: 100%;
  }

  @include media-breakpoint-down(lg) {
    width: 40px;
    height: 40px;
  }
}

.balance-item__desc {
  display: flex;
  align-items: center;
}

.balance-item__title {
  display: flex;
  align-items: center;
  font-size: 16px;

  &.tile__description {
    font-size: 16px;
  }

  @include media-breakpoint-down(xxl) {
    max-width: 50px;
  }

  @include media-breakpoint-down(lg) {
    max-width: unset;
    margin-bottom: 5px;
  }
}

.balance-item__main.tile__main {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: .5px;

  @include media-breakpoint-down(xl) {
    font-size: 20px;
  }
}

@import "src/styles/mixins";

.refunding {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  padding: 16px;
  background-color: var(--color-background);
  letter-spacing: 1px;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    gap: 16px;
  }

  &__label {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__status {
    display: flex;
    align-items: center;
    gap: 8px;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      gap: 4px;
    }
  }

  .round-button {
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
}
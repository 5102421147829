@import "src/styles/mixins";

.portfolio-page .filters-section__row {
  padding: 16px 0;
}

.portfolio-page .filters-section__row .projects-title {
  font-size: 40px;
  font-weight: bold;

  @include media-breakpoint-down(xl) {
    font-size: 24px;
  }
}

.account-portfolio .ellipse19 {
  width: 334px;
  height: 334px;
  left: 85%;
  top: -170px;
}

.account-portfolio .ellipse18 {
  left: 8%;
  top: 200px;
}

.portfolio-page .project-filter {
  width: 100%;
  justify-content: flex-end !important;
}

.whitelist-badge {
  min-height: 64px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border-radius: 16px;
  background-color: var(--color-bg-white-transparantize);
  color: var(--color-text-white);
  line-height: 1.4;
  letter-spacing: 1px;

  &.passed {
    background-color: var(--color-bg-violet-transparentize);
  }

  &.rejected {
    background-color: var(--color-bg-red-transparantize);
  }
}
@import "src/styles/mixins";

.cms-large-body {
  font-weight: 600;
  font-size: 40px;
  line-height: 1.26;
  margin-bottom: 16px;

  @include media-breakpoint-down(lg) {
    font-size: 32px;
  }

  @include media-breakpoint-down(md) {
    font-size: 24px;
  }
}

@import 'src/styles/mixins';

.homepage .features-section {
  margin-bottom: 76px;
  z-index: 2;

  @include media-breakpoint-down(lg) {
    margin-bottom: 40px;
  }
}

.features-item {
  height: 100%;
  width: 100%;
}

.features-item__content {
  padding-left: 145px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  @include media-breakpoint-down(xxl) {
    padding-left: 115px;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 0;
    justify-content: space-between;
  }
}

.features-item__description {
  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;
    padding-top: 105px;
    align-items: center;
  }
}

.features-item__title {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.26;
}

.features-item__text {
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.5px;

  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}

.features-item--buy .features-item__title {
  margin-bottom: 16px;
}

.features-item--buy .round-button img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.features-item--buy .round-button.btn {
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px;
  width: 100%;
}

.features-item--buy .round-button + .round-button {
  margin-top: 12px;
}

.features-item--lock, .features-item--earn {
  .round-button {
    line-height: 42px;
    padding: 7px;
    width: 100%;
  }
}

.features-slider {
  display: flex;
  margin: auto;
  user-select: none;
  width: 100%;
}

.features-slider .swiper-slide {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: 24px;
  padding: 31px;
  margin-right: 16px;
  margin-left: 16px;
  width: 400px;
  transition: opacity .15s linear;

  @include media-breakpoint-down(xxl) {
    width: 350px;
    margin-right: 12px;
    margin-left: 12px;
  }

  @include media-breakpoint-down(sm) {
    height: 322px;
    width: 263px;
    margin-right: 8px;
    margin-left: 8px;
    padding: 24px;
  }
}

.features-slider .swiper-slide.earn {
  background-image: url('../../../assets/features/earn-flame-bg.png');

  @include media-breakpoint-down(sm) {
    background-image: url('../../../assets/features/earn-flame-bg-v.png');
  }
}

.features-slider .swiper-slide.buy {
  background-image: url('../../../assets/features/buy-flame-bg.png');

  @include media-breakpoint-down(sm) {
    background-image: url('../../../assets/features/buy-flame-bg-v.png');
  }
}

.features-slider .swiper-slide.lock {
  background-image: url('../../../assets/features/lock-flame-bg.png');

  @include media-breakpoint-down(sm) {
    background-image: url('../../../assets/features/lock-flame-bg-v.png');
  }
}

@import 'src/styles/mixins';

.account-page {
  text-align: left;
}

.account-page h1.title {
  margin-bottom: 40px !important;

  @include media-breakpoint-down(md) {
    margin-bottom: 20px !important;
  }
}

.account-page .heading-row {
  padding-top: 40px;
  padding-bottom: 0;

  @include media-breakpoint-down(md) {
    padding-top: 5px;
  }
}

.kyc-section .ellipse23 {
  top: -150px;
  left: 85%;
  opacity: 1;
}

.kyc-section .kyc-badge__status p {
  font-size: 32px;
}


.stake-allocation-section {
  padding-bottom: 48px;

  @include media-breakpoint-down(lg) {
    padding-bottom: 24px;
  }
}

.stake-allocation-section .title {
  margin-bottom: 24px;
  font-size: 40px;
  line-height: 52px;

  @include media-breakpoint-down(lg) {
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 126%;
  }
}

.stake-allocation-section .bottom-description {
  font-size: 16px;
  line-height: 126%;
  letter-spacing: 1px;
}

.stake-allocation-section .ellipse18 {
  left: 8%;
  top: 50px;
}

.non-polygon-block, .non-polygon-block.col {
  max-width: 700px;
  margin: 50px auto;
  padding: 40px 25px;
}

.stake-block.row {
  margin-bottom: 40px;

  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
  }
}

.stake-form {
  display: flex;
  flex-direction: column;
}

.stake-form .tab-content {
  flex: 1 0;
}

.stake-form__input-icon {
  height: 48px;
}

.stake-block .nav-tabs {
  border-bottom: 1px solid var(--color-separator);
  margin-bottom: 26px;
}

.stake-block .nav-tabs .nav-link {
  border-width: 3px;
  border-radius: 4px;
  padding: 0 0 0.5em;
  margin-right: 32px;
  font-size: 32px;
  line-height: 35px;
  font-weight: 400;
  text-transform: capitalize;
}

.stake-block .nav-tabs .nav-link:focus,
.stake-block .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.stake-block .nav-tabs .nav-link.active {
  border-color: transparent transparent var(--color-text-violet) transparent;
  background-color: transparent;
  color: var(--color-text-white);
  font-weight: 600;
}

.stake-block .input-group {
  margin-bottom: 26px;
}

.stake-block__buttons {
  display: flex;
  width: 100%;
  margin: 0 0 5px;

  @include gap(16px);
}

.stake-block__buttons .btn.round-button {
  flex-basis: 50%;
  flex-grow: 1;
  margin: 0;
}

.stake-form .form-message {
  margin-bottom: 17px;
}

@include media-breakpoint-down(xl) {
  .account-nav .nav-link {
    font-size: 28px;
  }

  .stake-form {
    padding: 0;
  }

  .stake-block .nav-tabs .nav-link {
    margin-right: 16px;
    font-size: 22px;

    @include media-breakpoint-down(xxs) {
      font-size: 20px;
    }
  }
}

@include media-breakpoint-down(md) {
  .stake-block.tile {
    margin-left: 1px;
    margin-right: 1px;
    padding: 24px;
  }

  .stake-form .approval-steps__label {
    font-size: 12px;
    font-weight: normal;
  }
}

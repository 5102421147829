@import 'src/styles/mixins';

.press-kit {
  margin-bottom: 40px;

  .inquiries {
    display: flex;
    justify-content: center;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    letter-spacing: .5px;
    margin-top: 60px;
    text-align: center;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      letter-spacing: .5px;
      margin-top: 20px;
    }
  }
}

.firestarter-press-kit-page.marketing-page.page h1.title {
  margin: 0;
  display: flex;
  justify-content: flex-start;

  @include media-breakpoint-down(sm) {
    font-size: 32px;
    line-height: 126%;
    font-weight: 600;
    letter-spacing: .5px;
  }
}

.marketing-page.page h2.subtitle {
  margin: 0;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: .5px;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    letter-spacing: .5px;
  }
}

.press-kit__list.row {
  margin-top: 15px;

  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }
}

.press-kit__item {
  height: 520px;
  width: 396px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  @include media-breakpoint-down(sm) {
    height: 343px;
    min-width: 343px;
    text-align: center;

    h2, a, {
      width: 100%;
      font-size: 24px;
      line-height: 32px;
      font-weight: 600;

    }
  }
}

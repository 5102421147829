@import "src/styles/mixins";

.footer {
  padding: 3rem 0;
  background-color: var(--color-background);
  z-index: 1;
  margin-top: 100px;

  @include media-breakpoint-down(xl) {
    padding: 2rem 0;
    margin-top: 0;
  }

  .footer-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .footer__list {
    padding-right: 60px;

    @include media-breakpoint-down(xxl) {
      padding: 0;
    }
  }

  svg path {
    transition: .2s linear;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  &__logo img {
    width: 100%;
    height: 50px;
  }

  &__list-item,
  &__list-title {
    font-size: 16px;
    margin-bottom: 16px;
  }

  &__list-title {
    font-weight: 400;
    line-height: 1.26;
    letter-spacing: 1px;
    color: var(--color-text-white-transparantize);
  }

  &__list-item {
    line-height: 20px;
    letter-spacing: 0.3px;
  }

  & &__socials {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    flex-wrap: wrap;

    @include gap(16px);
  }

  &__socials {
    a:hover svg path {
      fill: var(--color-text-violet);
    }
  }

  &__socials-item {

    svg {
      width: 32px;
      height: 32px;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 18px;
  }

  .btn.round-button {
    margin: 0;
    padding-right: 40px;
    padding-left: 40px;
    line-height: 42px;
  }

  .quantstamp-marks {
    margin-left: 8px;
    display: flex;
    align-items: center;
  }
}

.row.copyrights-row {
  margin-top: 40px;
}

.quantstamp-link {
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.5);
  margin-left: 3px;
}

.quantstamp-link img {
  margin-left: 5px;
}

.footer-copyrights {
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 126%;
  letter-spacing: 1px;
  color: var(--color-text-white-transparantize);
  display: flex;
  justify-content: center;
  align-items: center;
}

.quantstamp-marks {
  li span,
  li a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.5);
  }

  a img {
    opacity: 0.44;
    margin-left: 8px;
    transition: 0.15s linear;
  }

  a:hover {
    color: var(--color-text-white);

    img {
      opacity: 1;
    }
  }

  li + li {
    margin-left: 12px;
  }
}

.footer__token-address {
  margin-top: 25px;
}

@import "src/styles/mixins";

.phases-list {
  $p: &;
  $flex: 1 0 30%;
  $border-radius: 16px;
  border-radius: $border-radius;
  border: 1px solid var(--color-main-grey);

  &__head {
    background-color: rgba(255, 255, 255, 0.04);
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid var(--color-main-grey);
    color: var(--color-text-white-transparantize);
    letter-spacing: 0.5px;
    padding: 10px 24px;
    border-radius: $border-radius $border-radius 0 0;

    div {
      flex: $flex;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__body {
    border-radius: 0 0 $border-radius $border-radius;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      border-radius: inherit;
    }
  }

  &__row {
    background-color: rgba(11, 11, 15, 0.4);
    padding: 20px 24px;
    display: flex;
    align-items: center;
    color: var(--color-text-white-transparantize);
    letter-spacing: 0.5px;
    gap: 8px;

    &:not(:last-child) {
      border-bottom: 1px solid #2D3449;
    }

    &.active {
      background-color: rgba(197, 139, 255, 0.1);
    }

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      padding: 16px;
    }
  }

  &__col {
    flex: $flex;

    &--title {
      color: var(--color-text-white);
      font-weight: 500;
    }

    @include media-breakpoint-up(md) {
      dt {
        display: none;
      }
    }

    @include media-breakpoint-down(md) {
      display: flex;
      justify-content: space-between;
      flex-basis: 100%;
      gap: 8px;
    }
  }
}

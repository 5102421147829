@import "src/styles/mixins";

.wrong-network-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  $p: &;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  &.embedded {
    padding: 24px;
    border: 1px solid var(--color-separator);
    border-radius: 24px;
  }

  &__icons {
    display: flex;

    #{$p}__icon {
      margin-right: 12px;
    }

    #{$p}__icon + #{$p}__icon {
      margin-left: -30px;
      z-index: -1;
    }
  }

  &__icon {
    margin-right: 12px;
    height: 40px;
    width: auto;

    @include media-breakpoint-down(md) {
      margin-bottom: 15px;
      margin-right: 0;
    }
  }

  &__text {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: 1px;
    margin-right: 12px;

    @include media-breakpoint-down(md) {
      margin-bottom: 15px;
      margin-right: 0;
      text-align: center;
    }
  }

  &__button {
    margin-left: 32px;

    @include media-breakpoint-down(md) {
      margin: 0;
    }
  }
}

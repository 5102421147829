@import "src/styles/mixins";

.no-projects-tile {
  height: 400px;
  padding: 46px;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  text-align: center;
}

.projects-page .no-projects-tile {
  height: 240px;
}

.no-projects-tile .dots {
  margin-bottom: 26px;
}

.no-projects-tile .dots > div {
  display: inline-block;
  margin: 0 6px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  box-shadow: 0px 4px 40px rgba(254, 150, 70, 0.4);
}

.no-projects-tile .dots > div:nth-child(1) {
  background: linear-gradient(116.94deg, #FF7D61 14.14%, #FE9E46 87.43%);
}

.no-projects-tile .dots > div:nth-child(2) {
  background: linear-gradient(116.94deg, #FEB446 14.14%, #FE9E46 87.43%);
}

.no-projects-tile .dots > div:nth-child(3) {
  background: linear-gradient(171.87deg, #FF6D6D -21.09%, #FEB446 115.63%);
}

.projects-top-container {
  padding-top: 45px;
  padding-bottom: 15px;

  @include media-breakpoint-down(lg) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.projects-upcoming,
.projects-live {
  padding-top: 32px;
  padding-bottom: 65px;

  @include media-breakpoint-down(lg) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.projects-completed {
  padding-top: 35px;
  padding-bottom: 110px;

  @include media-breakpoint-down(lg) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@import "src/styles/mixins";

.nft-banner-slider {
  width: 100%;
  height: 100%;
  z-index: 0;

  .swiper-wrapper {
    align-items: center;
  }
}


.nft-banner-slide {
  /*width: 454px;*/
  max-width: 454px;
  height: 484px;
  margin: 0 26px;

  &__asset {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 28px;
  }

  &:nth-child(even) {
    transform: translateY(50px);
  }

  &:nth-child(odd) {
    transform: translateY(-50px);
  }

  @include media-breakpoint-down(md) {
    max-width: 320px;
    height: 338px;

    &:nth-child(even) {
      transform: translateY(15px);
    }

    &:nth-child(odd) {
      transform: translateY(-15px);
    }
  }

  @include media-breakpoint-down(sm) {
    max-width: 230px;
    height: 228px;
  }
}
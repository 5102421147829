@import 'src/styles/mixins';

.tiers-table {
  color: var(--color-text-white);
  border-radius: 16px 16px 24px 24px;
  border: 1px solid #2D3449;
  backdrop-filter: blur(200px);
  overflow: hidden;
}

.tiers-table__head {
  color: var(--color-text-white-transparantize);
  display: grid;
  grid-template-columns: 1.15fr repeat(5, 1fr);
  background-color: rgba(255, 255, 255, 0.04);
}

.tiers-table__body {
  display: grid;
  grid-template-columns: 1.15fr repeat(5, 1fr);
}

.tiers-table__col {
  grid-column: span 1;
  grid-row: span 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @include media-breakpoint-down(xl) {
    font-size: 14px;
  }
}

.tiers-table__head .tiers-table__col {
  height: 72px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.tiers-table__body .tiers-table__col {
  border: 1px solid #2D3449;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: 0.5px;
  margin: -1px;
  min-height: 100px;
  padding: 5px;
}

.tiers-table__body .tiers-table__col--with-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tiers-table__body .tiers-table__col--with-image svg {
  margin-right: 5px;
}

.tiers-table__col.rowspan-1-4 {
  grid-row: 1 / span 4;
  grid-column: 6 / span 1;
}

.tiers-table__col.rowspan-2-4 {
  grid-row: 2 / span 4;
  grid-column: 1 / span 1;
}

.tiers-table__col.colspan-2-5 {
  grid-column: 2 / span 5;
}

.tiers-table__col .tooltip-wrapper {
  position: absolute;
  display: flex;
  width: 16px;
  height: 16px;
  z-index: 2;
}

.tiers-table__body .tiers-table__col--access {
  padding-left: 33px;
  justify-content: flex-start;

  @include media-breakpoint-down(xl) {
    font-size: 18px !important;
  }
}

.tiers-table__col--access .tooltip-wrapper {
  top: 13px;
  right: 13px;
}

.tiers-table__col--with-image {
  line-height: 1.5;
  letter-spacing: 1px;
}

.tiers-table__col--with-image svg {
  width: 60px;
  height: 60px;

  @include media-breakpoint-down(xl) {
    width: 40px;
    height: 40px;
  }
}

.tiers-table__col--with-image :is(.dynamic-image, img) {
  margin-right: 5px;
  width: 60px;
  height: 60px;

  @include media-breakpoint-down(xl) {
    width: 40px;
    height: 40px;
  }
}

.tiers-table__col--tier {
  justify-content: flex-start;
}

.tiers-table__col--tier :is(img, .dynamic-image) {
  margin-left: -5px;
}

.tiers-multiplier--rotate {
  font-size: 32px;
  transform: rotate(-25deg);
}

.tiers-table__col--surprise {
  padding: 5px;
}

.tiers-table__col--surprise span {
  font-size: 12px;
  line-height: 1.5;
}

.tiers-table__col--surprise :is(.dynamic-image, img) {
  width: 60px;
  height: 60px;
  margin-right: 5px;

  @include media-breakpoint-down(xl) {
    width: 40px;
    height: 40px;
  }
}

.tiers-table__col--surprise .purple-text {
  font-size: 16px;
}

.tiers-table__col.tiers-table__col--cooldown {
  flex-wrap: wrap;
  align-content: center;
  text-align: center;
}

.tiers-table__col--cooldown :is(img, .dynamic-image) {
  margin-bottom: 8px;
}

.tiers-table__col--cooldown .text {
  flex-basis: 100%;
  font-size: 16px;
}

.tiers-table__col--non-cooldown {
  padding: 13px 6px;
}

.tiers-table__col--non-cooldown .text {
  font-size: 12px;
  line-height: 1.4;
}

.tiers-table__col--non-cooldown :is(img, .dynamic-image) {
  margin-right: 8px;
  width: 60px;
  height: 60px;

  @include media-breakpoint-down(xl) {
    width: 40px;
    height: 40px;
  }
}

.tiers-table__col--leaders .text {
  display: block;
  max-width: 615px;
  margin: 0 auto;
}

.tiers-table__col--highlight {
  background-color: rgba(255, 255, 255, 0.04);
}

.flame-power-requirement {
  right: 10px;
  top: 10px;
}

.cooldown-period {
  right: 10px;
  top: 10px;
}

.tiers-tooltip {
  max-width: 392px;
}

.tooltip-modal.tile {
  border-radius: 16px;

  .modal-dialog {
    padding: 24px;
  }

  .modal-body {
    padding: 0;
  }
}

@import "src/styles/mixins";

//TODO: fix appearance on devices < 375px
.project-stats {

  &__tabs.fs-tabs.nav-tabs {
    margin-bottom: 32px;
    margin-top: 4px;

    @include media-breakpoint-down(xl) {
      gap: 24px;

      .nav-item {
        font-size: 18px;
      }
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 24px;
    }

    @include media-breakpoint-down(375px) {
      gap: 7px;

      .nav-item {
        font-size: 16px;
      }
    }
  }

  .project-statusbar {
    margin-bottom: 24px;

    @include media-breakpoint-down(md) {
      margin-bottom: 16px;
    }
  }

  .project-progress {
    margin-top: 8px;
  }

  &__list {
    margin-bottom: 24px;

    @include media-breakpoint-down(xxl) {
      font-size: 16px;
    }

    .value {
      white-space: nowrap;
    }
  }
}

@import 'src/styles/mixins';

.heat-row .color-strip {
  background: rgba(35, 40, 47, 0.9);
  height: 12px;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;

  @include media-breakpoint-down(sm) {
    height: 8px;
  }
}

.color-strip__overlay {
  background: linear-gradient(108.68deg, #C4A2FF 4.9%, #FF9393 74.14%);
  height: 100%;
  transition: .3s linear all;
  border-radius: 100px;
}

.col.status-bar-col {
  max-width: 610px;
}

.timezone-switcher {
  &.btn-group {
    border-radius: 24px;
    padding: 4px;
    border: 1px solid var(--color-separator);
  }

  input {
    appearance: none;
  }

  .timezone-option {
    position: relative;
    display: inline-block;
    min-width: 78px;
    padding: 9px 16px;
    font-weight: 700;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 1px;
    border-radius: 50px;
    cursor: pointer;
    transition: 0.15s linear;
    z-index: 0;

    &:first-child {
      &:before {
        position: absolute;
        content: '';
        left: calc(100% + 4px);
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 50px;
        background: rgba(255, 255, 255, 0.15);
        backdrop-filter: blur(50px);
        transition: 0.15s linear;
        z-index: -1;
      }

      &.active:before {
        left: 0;
      }
    }
  }

  label + label {
    margin-left: 4px;
  }
}

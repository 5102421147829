@import 'src/styles/mixins';

.tiers-section {
  padding-top: var(--section-padding-top);
  padding-bottom: var(--section-padding-bottom);
  background-image: url('../../../assets/tiers/tiers-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @include media-breakpoint-down(lg) {
    padding-top: var(--section-padding-mobile-top);
    padding-bottom: var(--section-padding-mobile-bottom);
  }
}

.tiers-section .title {
  margin-bottom: 56px;

  @include media-breakpoint-down(lg) {
    font-size: 32px;
    margin-bottom: 20px;
  }
}

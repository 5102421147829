@import "src/styles/mixins";

.nft-token-page {
  position: relative;
  padding-top: 48px;
  padding-bottom: 100px;
}

.ignition-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: -1;

  p {
    line-height: 1.5;
    letter-spacing: 0.5px;
    color: var(--color-text-white-transparantize);
  }

  &__line {
    width: 100%;
    height: 3px;
    margin-bottom: 10px;
    background: linear-gradient(
        90deg,
        rgba(254, 70, 70, 0) 0%,
        rgba(255, 255, 255, 0.15) 10%,
        #FE4646 43.55%,
        rgba(255, 255, 255, 0.15) 90%,
        rgba(254, 70, 70, 0) 100%
    );
    background-size: 100% 3px;
    animation: linearLoader infinite 2s forwards linear;
  }
}


@keyframes linearLoader {
  0% {
    background-position: -100vw 0;
  }

  100% {
    background-position: 100vw 0;
  }
}

.nft-token-page {
  &:before {
    position: fixed;
    content: '';
    width: 50%;
    height: calc(100% + 81px);
    top: -81px;
    left: 0;
    background-image: url('../../assets/nft/nft-page-bg.png');
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    z-index: -1;
  }

  .subtitle {
    font-size: 24px;
    line-height: 1.5;
    letter-spacing: 0.5px;
    color: var(--color-text-white-transparantize);
    margin: 0;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
  }

  .tile {
    border-radius: 32px;

    & + .tile {
      margin-top: 18px;
    }
  }
}

.nft-token-section {
  margin-top: 44px;

  @include media-breakpoint-down(md) {
    margin-top: 24px;
  }

  .loader:first-of-type {
    margin: auto;
  }
}

.nft-token-asset-box {
  display: flex;
  width: 100%;

  @include media-breakpoint-down(xl) {
    max-width: 500px;
  }
}

.nft-token-asset {
  width: 100%;
  min-height: 400px;
  border-radius: 28px;
}

.nft-token {
  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__description {
    margin-top: 24px;
    margin-bottom: 32px;

    dl {
      margin-bottom: 28px;
    }

    dt,
    dd {
      display: inline;
    }

    dt {
      font-weight: 400;
    }

    dd {
      font-weight: 700;
    }

    ul {
      padding-left: 1rem;
    }
  }


  &__description-list ~ p {
    margin-top: 28px;
    margin-bottom: 0;
  }

  &__help {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      text-align: center;
      gap: 24px;

      .round-button {
        width: 100%;
      }
    }
  }

  &__help p {
    margin-bottom: 0;
  }
}

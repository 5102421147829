@import "src/styles/mixins";

.kyc-badge {
  &__wallet {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;

    @include media-breakpoint-down(sm) {
      justify-content: center;
    }

    .copiable {
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 600;
      letter-spacing: 1px;
    }
  }

  &__icon {
    width: 32px;
    height: 32px;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      margin-top: 15px;
    }
  }

  &__status {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.26;
  }

  &__description {
    width: 100%;

  }

  &__message {
    margin: 0;
    line-height: 1.5;
    letter-spacing: 1px;
  }

  &__action {
    flex-shrink: 0;

    @include media-breakpoint-down(sm) {
      margin-top: 24px;
      width: 100%;

      .btn {
        width: 100%;
      }
    }
  }
}

.kyc-approved-mark {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 26px;
  padding: 20px 40px;

  @include media-breakpoint-down(md) {
    padding: 20px;
  }

  svg {
    margin-right: 12px;
  }
}

@import 'src/styles/mixins';
$background-image: url('../../assets/top-banner/space.jpg');
$shadow-gradient: linear-gradient(180deg, rgba(11, 11, 15, 0) 0%, rgba(11, 11, 15, 0.9) 43.37%, #0B0B0F 84.69%);

.main-background {
  height: 100%;
  width: 100%;
  background-image: $background-image;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &__shadow-top {
    position: absolute;
    width: 100%;
    height: 150px;
    z-index: 2;
    background: $shadow-gradient;
    left: 0;
    top: 0;
    transform: matrix(1, 0, 0, -1, 0, 0);

    @include media-breakpoint-down(sm) {
      height: 120px;
    }
  }
}





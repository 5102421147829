@import "src/styles/mixins";

.tutorial-section {
  --tile-padding: 35px;
  .tile {
    height: 100%;
  }

  @include media-breakpoint-down(md) {
    --tile-padding: 24px;
  }
}

.tutorial-tile {
  border: 1px solid var(--color-separator);
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
  min-height: 228px;

  @include media-breakpoint-down(md) {
    min-height: 352px;
    background-position: top center;
  }

  &--markets {
    background-image: url('../../../assets/lottery/tutorial-bg-1.png');

    .round-button img {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }

    @include media-breakpoint-down(md) {
      background-image: url('../../../assets/lottery/tutorial-bg-1-mobile.png');
    }
  }

  &--guide {
    background-image: url('../../../assets/lottery/tutorial-bg-2.png');

    @include media-breakpoint-down(md) {
      background-image: url('../../../assets/lottery/tutorial-bg-2-mobile.png');
    }
  }

  &__content {
    height: 100%;
    padding-left: calc(195px - var(--tile-padding));
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include media-breakpoint-down(md) {
      padding-left: 0;
      padding-top: calc(130px - var(--tile-padding));
      text-align: center;
      align-items: center;
    }
  }

  @include media-breakpoint-down(md) {
    .tile__main {
      max-width: 230px;
    }

    .tile__description {
      max-width: 200px;
      color: var(--color-text-white);
      margin-bottom: 16px;
    }
  }

  &__buttons {
    margin-top: auto;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    @include media-breakpoint-down(md) {
      margin-top: 0;

      .round-button {
        width: 100%;
      }
    }
  }
}

.tutorial-slider {
  display: flex;
  margin: auto;
  user-select: none;
  width: 100%;

  .swiper-slide {
    width: calc(var(--container-max-width) / 2 - 24px);
    margin: 0 12px;

    @include media-breakpoint-down(lg) {
      width: 456px;
    }

    @include media-breakpoint-down(md) {
      width: 263px;
      margin: 0 8px;
    }
  }
}
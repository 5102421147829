@import 'src/styles/mixins';

.partnerships {
  padding-top: var(--section-padding-top);
  padding-bottom: var(--section-padding-bottom);

  @include media-breakpoint-down(lg) {
    padding-top: var(--section-padding-mobile-top);
    padding-bottom: var(--section-padding-mobile-bottom);
  }
}

.partnerships .title:first-child {
  margin-bottom: 54px;
  position: relative;
  z-index: 110;

  @include media-breakpoint-down(lg) {
    margin-bottom: 34px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 32px;
  }
}

.partnerships .row {
  --bs-gutter-y: 2rem;
  --bs-gutter-x: 2rem;
}

.homepage .partnerships-col {
  max-height: 112px;
  display: flex;
  align-items: center;
}

.partnerships-item {
  width: 100%;
}

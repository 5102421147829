@import 'src/styles/mixins';

.hero-row {
  z-index: 100;
  height: 100%;
  align-content: center;

  @include media-breakpoint-down(sm) {
    margin-bottom: -100px;
  }
}

.hero-row [class*="col"] {
  z-index: 1;
}

.hero-row .hero-row__main {
  margin-top: 12%;
  position: relative;
}

.homepage .hero-row .hero-row__main {
  @include media-breakpoint-down(xs) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 72px;
    margin-bottom: -115px;
    height: 100%;
    margin-top: unset;
  }
}

.hero-row .title {
  font-size: 64px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  position: relative;
  margin-bottom: 10px;
  z-index: 2;

  @include media-breakpoint-down(md) {
    font-size: 32px;
  }
}

.homepage .hero-row h1.title {
  font-size: 38px;
  text-align: center;
  margin-bottom: 10px;
  line-height: 1.3;

  @include media-breakpoint-down(sm) {
    font-size: 32px;
    line-height: 1.12;
  }
}

.hero-row .subtitle {
  font-size: 36px;
  line-height: 1.25;

  @include media-breakpoint-down(sm) {
    font-size: 24px;
  }
}

.hero-row .hero-buttons {
  margin-top: 34px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: stretch;
  z-index: 2;

  @include gap(16px);

  @include media-breakpoint-down(xs) {
    flex-direction: column;
    display: flex;
    width: 100%;
    justify-content: center;

    .buy-flame-dropdown .btn-primary {
      justify-content: center;
      padding: 0.375rem 0.75rem;
      line-height: 42px;
    }
  }
}

.hero-buttons__countdown {
  display: inline-flex;
  align-items: center;
  padding: 8px 24px 8px 8px;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.2);

  @include gap(16px);
}

.hero-buttons__whitelist-date {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.26;
  letter-spacing: 1px;
}

.hero-buttons .round-button.dark {
  @include blurred-dark-background(40px);
}

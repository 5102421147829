@import "src/styles/mixins";

.add-network-dropdown {

  &__button.dropdown-toggle {
    display: flex;
    align-items: center;

    &::after {
      transition: 0.3s linear;
      border: none;
      margin-left: 12px;
      width: 12px;
      height: 7px;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url('../../../assets/arrow-black.svg');
      color: black;
    }
  }

  &__menu.dropdown-menu {
    inset: 10px auto auto 0 !important;
    min-width: 100%;
    overflow: hidden;
    @include blurred-dark-background(40px, relative, 0.06);
    border-radius: 16px;
    z-index: 2;
    font-size: 16px;
  }

  &__item {
    img {
      width: 32px;
      height: 32px;
      margin-right: 10px;
    }

    &.dropdown-item {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-weight: 700;
      color: var(--color-text-white);
      cursor: pointer;
      padding: 0.5rem 1rem;

      &:hover,
      &.active,
      &:active {
        background-color: inherit;
        color: var(--color-text-violet);
      }
    }
  }
}

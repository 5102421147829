@import "src/styles/mixins";

.lockup-content {
  .buy-flame-dropdown .dropdown-toggle {
    line-height: 50px;
  }
}

.lockup-form {
  position: relative;
  transition: opacity 0.15s linear;
  $p: &;

  &.hidden {
    @include fadingHidden;
  }

  &--unlock {
    .loader {
      margin-left: auto;
      margin-right: auto;
    }

    #{$p}__buttons {
      flex-wrap: wrap;
    }
  }

  .btn-close {
    position: absolute;
    right: var(--tile-padding);
    top: var(--tile-padding);
    width: 24px;
    height: 24px;
    background-size: 24px;
    z-index: 2;
  }

  &__tier {
    .loader {
      --loader-size: 24px;
    }

    .dynamic-image {
      width: 32px;
      height: 32px;
    }
  }

  &__input-group {
    #{$p}__input.form-control {
      font-size: 24px;
    }
  }

  &__balances {
    margin: 20px 0;

    .name {
      font-size: 16px;
    }

    .value {
      font-size: 24px;
    }
  }

  .info-list .loader {
    --loader-size: 1.26em;
  }

  &__options {
    .loader {
      margin: 80px auto;
    }
  }

  & &__options-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    @include media-breakpoint-down(sm) {
      gap: 12px;
    }
  }

  &__use-hiro {
    display: flex;
    align-items: center;
    padding: 1.5rem;
    border: 1px solid var(--color-separator);
    border-radius: 1rem;
    letter-spacing: 1px;

    img {
      margin-right: 12px;
    }

    .custom-switch {
      margin-left: auto;
    }
  }

  &__buttons {
    display: flex;
    gap: 16px;

    .round-button {
      flex-basis: 100%;
      flex-grow: 1;
    }

    .round-button.transparent {
      background-color: transparent;
      border-color: transparent;
      backdrop-filter: none;
    }

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
  }
}

.locks-table {
  $p: &;

  @include media-breakpoint-between(xl, xxl) {
    font-size: 14px;
  }

  &__head-row {
    border-bottom: 1px solid var(--color-separator);
  }

  @include media-breakpoint-up(md) {
    &__row:not(:last-child) {
      border-bottom: 1px solid var(--color-separator);
    }
  }

  &__row.unlocked {
    color: var(--color-text-white-transparantize);
  }

  @include media-breakpoint-down(md) {
    &__row {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      margin-bottom: 32px;
    }

    thead {
      display: none;
    }
  }

  th {
    line-height: 1.25;
    letter-spacing: 0.5px;
    font-weight: 400;
    color: var(--color-text-white-transparantize);
    padding: 14px 0;
  }

  td {
    padding-top: 20px;
    padding-bottom: 20px;
    letter-spacing: 0.5px;
    line-height: 1em;
    white-space: nowrap;

    @include media-breakpoint-between(xl, xxl) {
      letter-spacing: normal;
    }

    @include media-breakpoint-down(md) {
      flex-basis: 33%;
      padding-bottom: 8px;
      padding-top: 16px;

    }
  }
  .simple-table__cell {
    @include media-breakpoint-down(md) {
      display: flex;
      align-items: center;
      justify-content: inherit;
      width: 100%;
      height: 100%;
    }
  }

  td, th {
    &:not(:first-child):not(:last-child) {
      text-align: center;
    }

    @include media-breakpoint-up(md) {
      &:not(:first-child) {
        padding-left: 10px;
      }

      &:not(:last-child) {
        padding-right: 10px;
      }
    }


    @include media-breakpoint-between(xl, xxl) {
      &:not(:first-child) {
        padding-left: 6px;
      }

      &:not(:last-child) {
        padding-right: 6px;
      }
    }
  }

  &__date {
    @include media-breakpoint-down(md) {
      order: -3;
      border-bottom: 1px solid var(--color-separator);
      justify-content: flex-start;
    }
  }

  &__period {
    .simple-table__cell {
      white-space: nowrap;
    }

    svg {
      margin-left: 4px;
    }

    @include media-breakpoint-down(md) {
      justify-content: center;
      order: -2;
      border-bottom: 1px solid var(--color-separator);
    }
  }

  &__locked {
    @include media-breakpoint-down(md) {
      justify-content: flex-start;
    }
  }

  &__apy {
    @include media-breakpoint-down(md) {
      justify-content: center;
    }
  }

  &__rewards {
    @include media-breakpoint-down(md) {
      justify-content: flex-end;
    }
  }

  &__action {
    width: 110px;

    .round-button.small {
      line-height: 28px;
    }

    @include media-breakpoint-up(md) {
      .simple-table__cell {
        display: flex;
        justify-content: flex-end;
      }
    }

    @include media-breakpoint-down(md) {
      order: -1;
      border-bottom: 1px solid var(--color-separator);
      justify-content: flex-end;
    }
  }

  @include media-breakpoint-down(md) {
    &__locked,
    &__apy,
    &__rewards {
      display: block;

      &:before {
        display: flex;
        justify-content: inherit;
        content: attr(data-label);
        margin-bottom: 8px;
        color: var(--color-text-white-transparantize);
      }
    }
  }

  @include media-breakpoint-down(400px) {
    td {
      &#{$p}__date {
        flex-basis: 100%;
        border-bottom: none;
        padding-bottom: 4px;
      }

      &#{$p}__period,
      &#{$p}__action {
        flex-basis: 50%;
        padding-top: 4px;
      }

      &#{$p}__period {
        justify-content: flex-start;
      }
    }
  }
}

.lockup-option {
  position: relative;
  border: 1px solid var(--color-text-white-transparantize);
  border-radius: 16px;
  cursor: pointer;
  padding: 16px;
  overflow: hidden;
  transition: 0.15s linear;

  &:hover:not(.active):not(.disabled) {
    box-shadow: 1px 1px 10px var(--color-text-violet);
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.active {
    border-color: var(--color-text-violet);
    background-color: rgba(197, 139, 255, 0.1);
  }

  input {
    display: none;
  }

  &__title {
    font-size: 24px;
    line-height: 1.26;
    font-weight: 600;
    letter-spacing: 1px;

    small {
      font-size: 0.675em;
    }

    @include media-breakpoint-down(md) {
      font-size: 20px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 16px;

      small {
        font-size: 0.9em;
      }
    }
  }

  &__desc {
    @include media-breakpoint-down(md) {
      font-size: 14px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 12px;
    }
  }

  &__tooltip-trigger {
    position: absolute;
    right: 10px;
    top: 4px;
    z-index: 2;
  }

  &__tooltip {
    min-width: 392px;
  }
}

.lockup-tooltip {
  &__title svg {
    width: 24px;
    height: 24px;
  }

  &__subtitle {
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 1px;
    color: var(--color-text-white-transparantize);
  }

  &__list {
    list-style: none;
    padding-left: 0;

    li {
      display: flex;
      justify-content: space-between;
      align-self: center;
      line-height: 1.26;
      letter-spacing: 0.5px;
      margin-bottom: 16px;
    }
  }

  &__rewards-list {
    list-style-type: '– ';
    list-style-position: inside;
    padding-left: 0;
  }
}

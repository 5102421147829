@import 'src/styles/mixins';

.quantstamp-section {
  padding-top: var(--section-padding-top);
  padding-bottom: var(--section-padding-bottom);

  @include media-breakpoint-down(lg) {
    padding-top: var(--section-padding-mobile-top);
    padding-bottom: var(--section-padding-mobile-bottom);
  }
}

.quantstamp-badge {
  position: relative;
  text-decoration: none;
  display: block;
  height: 464px;
  width: 100%;
  background-image: url('../../../assets/quantstamp/background.png');
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;

  @include media-breakpoint-down(lg) {
    height: 350px;
  }

  @include media-breakpoint-down(sm) {
    height: 260px;
  }
}

.quantstamp-badge.tile--with-shadow {
  background-color: rgba(35, 40, 47, 0.4);
}

.quantstamp-badge:hover {
  box-shadow: none;
}

.quantstamp-badge__arrow {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 45px;
  height: 45px;
  z-index: 2;
  transition: .15s ease-in-out;

  @include media-breakpoint-down(sm) {
    width: 30px;
    height: 30px;
    top: 20px;
    right: 20px;
  }
}

.quantstamp-badge:hover .quantstamp-badge__arrow {
  transform: translateX(5px) translateY(-5px);
}

.quantstamp-badge__title {
  font-size: 32px;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 8px;
  color: var(--color-text-white);

  @include media-breakpoint-down(sm) {
    font-size: 24px;
  }
}

.quantstamp-badge__logo {
  max-width: 484px;
  width: 100%;
  height: auto;

  @include media-breakpoint-down(sm) {
    width: 80%;
  }
}

@import "src/styles/mixins";

.marketing-page.page.faq-page h1.title {
  margin-bottom: 0;

  @include media-breakpoint-down(lg) {
    margin-bottom: 16px;
  }
}

.faq-page {
  .subtitle {
    margin-bottom: 32px;
    line-height: 1.5;
    letter-spacing: 0.5px;
  }

  .faq-btn {
    @include media-breakpoint-down(lg) {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

@import "src/styles/mixins";

.project-statusbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 12px;

  &__title {
    font-weight: 600;
    font-size: 32px;
    line-height: 1.26;

  }

  &__date {
    font-size: 24px;
    font-weight: 600;
    color: var(--color-text-violet);
  }

  @include media-breakpoint-down(xl) {
    &__title {
      font-size: 28px;
    }

    &__date {
      font-size: 18px;
    }
  }
}

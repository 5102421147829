@import "src/styles/mixins";

.portfolio-item {
  &.tile {
    position: relative;
    background: var(--color-main-dark);

    @include media-breakpoint-down(lg) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  &__main {
    display: grid;
    grid-template-columns: var(--portfolio-columns);
    grid-gap: 18px;


    @include media-breakpoint-down(lg) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;

      .wrong-network-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;

        &__icon {
          margin-bottom: 15px;
        }

        &__text {
          margin-bottom: 15px;
          margin-right: 0;
        }

        &__button {
          margin-left: 0;
        }
      }
    }
  }

  &__name {
    margin-bottom: 0;
    font-size: 32px;
    font-weight: 600;
    max-width: 250px;
    text-align: left;

    @include media-breakpoint-down(xl) {
      font-size: 24px;
    }

    a {
      text-decoration: none;
    }
  }

  &__logo {
    position: relative;
    width: 90px;
    height: 90px;
    margin-right: 8px;
    text-align: center;
    flex-shrink: 0;

    @include media-breakpoint-down(lg) {
      width: 80px;
      height: 80px;
    }
  }

  &__logo-image {
    width: 100%;
    height: 100%;
  }

  &__network-image {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  &__col {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    @include gap(16px);

    @include media-breakpoint-down(xl) {
      font-size: 16px;

      &:not(.portfolio-item__col--title) {
        justify-content: space-between;
      }
    }

    span:empty {
      position: relative;
      margin-left: 30%;

      &:before {
        content: '–';
      }
    }

    span:last-child {
      font-weight: 700;
      letter-spacing: 1px;
      text-transform: uppercase;

      @include media-breakpoint-down(xl) {
        letter-spacing: unset;
      }
    }

    .round-button {
      white-space: nowrap;
      width: 100%;

      @include media-breakpoint-up(lg) {
        max-width: 250px;
      }

      @include media-breakpoint-down(lg) {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &--wrong-network {
      display: grid;
      padding-left: 32px;
      grid-column-start: 2;
      grid-column-end: 7;
      font-size: 18px;

      @include media-breakpoint-down(lg) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
      }
    }

    &--nodes {
      display: grid;
      padding-left: 32px;
      grid-column-start: 2;
      grid-column-end: 6;
      font-size: 18px;

      @include media-breakpoint-down(lg) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
      }
    }
  }

  &__refunding {
    width: 100%;
    margin-top: 24px;
  }
}

@import 'src/styles/mixins';

.below-banner-section {
  margin-bottom: 40px;
  margin-top: 60px;
  z-index: 1;

  @include media-breakpoint-down(xs) {
    margin-top: 100px;
  }
}

.below-banner-section .container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.below-banner {
  width: 80%;
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-down(xxl) {
    width: 90%
  }

  @include media-breakpoint-down(xl) {
    width: 100%
  }

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__powered-by {
    width: 70%;

    &__icons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      @include media-breakpoint-down(md) {
        margin-top: 5px;
      }

      @include media-breakpoint-down(md) {
        margin-top: 0;
      }
    }
  }

  &__contract {
    @include media-breakpoint-down(lg) {
      margin-top: 10px;
    }
    @include media-breakpoint-down(md) {
      margin-top: 0;
    }
  }

  &__powered-by, &__contract {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }

    &__title {
      font-size: 16px;
      letter-spacing: 1px;
      font-weight: 600;
      color: grey;
      text-transform: uppercase;

      @include media-breakpoint-down(md) {
        font-size: 12px;
      }
    }
  }

  &__token-address {
    width: 252px;
    margin-top: 10px;

    @include media-breakpoint-down(md) {
      margin-top: 5px;
    }
  }
}

@import "src/styles/mixins";

.confirm-popup {
  text-align: center;

  &.modal {
    z-index: 1070;
  }

  &__backdrop.modal-backdrop {
    z-index: 1060;
  }

  .modal-dialog {
    max-width: 604px;

    @include media-breakpoint-down(md) {
      max-width: 450px;
    }
  }

  .modal-header {
    justify-content: center;
  }

  .modal-footer {
    $gap: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $gap;

    & > * {
      margin: 0;
      flex-basis: calc(50% - #{$gap} / 2);
      flex-grow: 1;
    }

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;

      & > * {
        flex-basis: 100%;
      }
    }
  }
}
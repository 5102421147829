@import 'src/styles/mixins';

.project-social-links {
  display: flex;
  align-items: center;
  @include gap(12px);

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 54px;background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(40px);
    border-radius: 50%;
    transition: .2s linear;

    svg, img {
      width: 28px;
      height: 28px;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.35);
    }

    @include media-breakpoint-down(md) {
      width: 40px;
      height: 40px;

      svg, img {
        width: 22px;
        height: 22px;
      }
    }
  }
}

@import 'src/styles/mixins';

.faq-section {
  padding-top: var(--section-padding-top);
  padding-bottom: var(--section-padding-bottom);

  @include media-breakpoint-down(lg) {
    padding-top: var(--section-padding-mobile-top);
    padding-bottom: var(--section-padding-mobile-bottom);
  }
}

.faq-column .title {
  font-family: Archivo;
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 126%;
  color: #FFFFFF;
  margin-bottom: 26px;

  @include media-breakpoint-down(sm) {
    font-size: 32px;
  }
}

.faq-column .card.accordion .card-header {
  padding: 25px;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
    max-height: 80px;
  }
}

.faq-column .card.accordion .card-body {
  padding: 0 1.5rem 1rem 1.5rem;
  font-size: 16px;
  line-height: 130%;
  margin-right: 50px;

  @include media-breakpoint-down(sm) {
    margin-right: 0;
  }
}

.faq-column .card.accordion .btn {
  font-size: 24px;
  padding: 0;
  text-align: left;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

.faq-column .card.accordion svg {
  @include media-breakpoint-down(sm) {
    width: 13px;
  }
}

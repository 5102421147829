@import "mixins";
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,400;0,500;0,600;0,700;1,700&display=swap');

html,
body {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: var(--base-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-background);
  min-height: 100%;
  color: var(--color-text-white)
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

header {
  z-index: 3;
}

ul, ol, dl {
  margin: 0;
  padding: 0;
}

dd {
  margin-bottom: 0;
  margin-left: 0;
}

.nav-link {
  color: var(--color-text-white);

  &:hover,
  &:focus {
    color: var(--color-text-violet);
  }

  &.active {
    border: none;

    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 3px;
      top: 100%;
      left: 0;
      background-color: var(--color-text-violet);
      border-radius: 2px;
    }
  }
}

.page {
  flex: 1 0;
}

section {
  position: relative;
}

.background {
  pointer-events: none;
}

section .background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.page h1.title {
  font-size: 56px;
  font-weight: 700;
  line-height: 112%;
  letter-spacing: -1px;
  text-align: left;
  z-index: 1;

  @include media-breakpoint-down(lg) {
    font-size: 40px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 32px;
  }
}

h2.title, h2.subtitle {
  font-size: 40px;
  font-weight: 600;
  line-height: 126%;

  @include media-breakpoint-down(lg) {
    font-size: 32px;
  }

  @include media-breakpoint-down(md) {
    font-size: 28px;
  }
}

a {
  color: var(--color-text-white);
  transition: 0.2s linear;
}

a:hover {
  color: var(--color-text-violet);
  text-decoration: none;
}

.red-text {
  color: var(--color-text-red);
}

.success-text {
  color: var(--color-text-success);
}

.purple-text {
  color: var(--color-text-violet);
}

.white-transparent-text {
  color: var(--color-text-white-transparantize);
}

.white-text {
  color: var(--color-text-white);
}

.gradient-text {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text--orange {
  background-image: linear-gradient(94.07deg, #FF6D43 2.02%, #FE5145 27.83%, #FF9B38 55.36%, #FE4B61 78.24%);
}

.gradient-text--purple {
  background-image: var(--color-main-gradient-text);
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.text-simple {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.5px;
}

.text-big {
  font-size: 20px;
  line-height: 1.26;
}

.text-wide {
  letter-spacing: 1px;
}

.bordered {
  border: 1px solid var(--color-text-white-transparantize);
}

.btn:focus {
  box-shadow: none !important;
}

.tile {
  padding: var(--tile-padding);
  background-color: #15171C;
  backdrop-filter: blur(80px);
  border-radius: 24px;
  color: var(--color-text-white);
}

.tile--with-shadow {
  padding: var(--tile-padding);
  background-color: rgba(35, 40, 47, 0.7);
  box-shadow: inset 2px 2px 2px rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(80px);
  border-radius: 32px;
}

.tile__description {
  color: var(--color-text-white-transparantize);
  font-size: 16px;
  line-height: 126%;
  letter-spacing: 1px;
}

.tile__main {
  font-weight: 600;
  font-size: 32px;
  line-height: 126%;

  @include media-breakpoint-down(lg) {
    font-size: 24px;
  }
}

.info-list {
  font-size: 16px;
  line-height: 1.26;
  letter-spacing: 1px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      gap: 4px;

      & > * {
        flex-basis: 100%;
      }
    }
  }

  & > div + div {
    margin-top: 16px;
  }

  & .name {
    font-weight: 400;
    color: var(--color-text-white-transparantize);
  }

  & .value {
    font-weight: 600;
    margin-bottom: 0;
    text-align: right;

    @include media-breakpoint-down(md) {
      text-align: left;
    }
  }
}

.input-group {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid var(--color-text-white-transparantize);
  backdrop-filter: blur(20px);
  padding: 10px 16px;
  border-radius: 16px;

  &.invalid {
    border-color: var(--color-text-red);
  }

  @include media-breakpoint-down(sm) {
    padding: 10px;
  }
}

.form-row {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 24px;
}

.form-group {
  margin-bottom: 24px;
}

.form-row .form-group {
  margin-bottom: 0;
}

.form-control {
  &::placeholder {
    color: var(--color-text-white-transparantize);
  }

  &, &:focus, &:active {
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid var(--color-text-white-transparantize);
    backdrop-filter: blur(20px);
    padding: 16px;
    border-radius: 16px;
    box-shadow: none;
    color: var(--color-text-white);

    @include media-breakpoint-down(sm) {
      padding: 10px;
      font-size: 14px;
    }
  }
}

.form-control[readonly] {
  background: rgba(255, 255, 255, 0.08);
  color: var(--color-text-white-transparantize);
}

.form-check, .form-check-input, .form-check-label {
  cursor: pointer;
}

.form-check .invalid-feedback:empty {
  display: none;
}

.form-check {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  padding-left: 0;
}

.form-check-input {
  width: 18px;
  height: 18px;
  margin: 0 15px 0 0;
  background-color: var(--color-background);
  border: 2px solid var(--color-text-white-transparantize);
}

.form-check .form-check-input {
  float: none;
  flex-shrink: 0;
  margin: 0 15px 0 0;
}

.form-check-input:focus {
  box-shadow: 0 0 0 0 0.25rem var(--color-text-white-transparantize);
}

.form-check-input:checked[type="checkbox"] {
  background-color: transparent;
  border-color: var(--color-text-violet);
}

.form-check-input:checked[type="checkbox"] {
  background-image: url('../assets/checkbox-checked.svg');
}

.form-check-input:checked[type="radio"] {
  background: var(--color-text-violet);
  background-clip: content-box;
  padding: 1px;
  border-color: var(--color-text-violet);
}

.form-label {
  color: var(--color-text-white-transparantize);

  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}

.form-label, .form-check-label {
  line-height: 1.26;
  letter-spacing: 1px;
}

.input-group-big {
  border-radius: 16px;
}

.input-group-big .form-control {
  font-size: 24px;
}

.input-group-prepend {
  display: flex;
  align-items: center;
  margin-right: 16px;

  @media (max-width: 500px) {
    margin-right: 2px;
  }
}

.input-group-prepend img {
  margin-right: 12px;

  @media (max-width: 500px) {
    margin-right: 0;
  }
}

.input-group-prepend span {
  font-weight: 600;
  font-size: 20px;
  line-height: 126%;
  letter-spacing: 1px;

  @media (max-width: 1199px) {
    display: none;
  }
}

.input-group-append {
  margin-left: 16px !important;
}

.input-group .form-control {
  padding: 0;
  background: transparent;
  border: none;
  backdrop-filter: none;
  text-align: right;
  line-height: 126%;
  appearance: none;
  -moz-appearance: textfield;
}

@media (max-width: 1399px) {
  .input-group-prepend span {
    font-size: 16px;
  }
}

.input-group .form-control::-webkit-outer-spin-button,
.input-group .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-group .form-control::placeholder {
  color: var(--color-text-white-transparantize);
}

.input-group .form-control:focus {
  box-shadow: none;
}

.custom-switch {
  --switch-size: 26px;
  position: relative;
  display: inline-flex;
  cursor: pointer;
}

.custom-switch .custom-control-label {
  background-color: var(--color-background);
  border: 2px solid var(--color-background);
  height: calc(var(--switch-size) + 4px);
  width: calc(var(--switch-size) * 2 - 2px);
  border-radius: calc(var(--switch-size) * 2 - 2px);
  transition: 0.15s linear;
  cursor: pointer;
}

.custom-switch .custom-control-input {
  position: absolute;
  left: 2px;
  top: 2px;
  appearance: none;
  width: var(--switch-size);
  height: var(--switch-size);
  border-radius: 50%;
  background: #2D3449;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.01), 0px 3px 1px rgba(0, 0, 0, 0.03);
  transition: 0.15s linear;
  cursor: pointer;
}

.custom-switch .custom-control-input:checked {
  left: calc(100% - 2px - var(--switch-size));
}

.custom-switch .custom-control-input:checked ~ .custom-control-label {
  background-color: var(--color-text-violet);
  border-color: var(--color-text-violet);
}

.form-message {
  border-radius: 8px;
  padding: 8px 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 0.05px;
  transition: 0.15s linear;
  visibility: visible;
  opacity: 1;

  &.hidden {
    @include fadingHidden;
  }

  svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;

    path {
      fill: var(--color-text-white);
    }
  }

  &--warning {
    background-color: var(--color-warning-bg);
    backdrop-filter: blur(20px);
    color: var(--color-text-white);
  }

  &--error {
    color: var(--color-text-red);
  }

  &--no-padding {
    padding: 0;
  }
}

.custom-alert {
  margin-bottom: 1rem;
  padding: 1.25rem;
  border-radius: 1rem;
}

.custom-alert-dark {
  color: var(--color-text-white);
  border: 1px solid var(--color-separator);
  background-color: var(--color-background);
}

.navbar-inner {
  background: transparent;
}

.bn-notify-custom {
  transition: .2s linear;
}

.bn-notify-notification-link:hover {
  color: var(--color-text-white);
}

.bn-notify-notification-link:hover .bn-notify-notification-info-message {
  color: var(--color-text-violet);
}

.custom-scroll {
  scrollbar-color: rgba(255, 255, 255, 0.1) transparent;
  scrollbar-width: 6px;
  overflow-scrolling: touch;
  overflow: auto;
  overscroll-behavior: none none;
  -webkit-overflow-scrolling: touch;
}

.custom-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  transition: .2s linear;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.custom-scroll::-webkit-scrollbar-button {
  display: none;
}

@media (max-width: 500px) {
  .mobile-scroll {
    overflow: hidden;
    height: 100%;
  }
}

.hide-scroll {
  overflow: hidden;
  height: 100%;
}

.back-link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 24px;

  @include media-breakpoint-down(md) {
    margin-bottom: 16px;
  }
}

.back-link .btn.round-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  margin-right: 12px;
}

.back-link .round-button svg {
  margin: 0;
}

.back-link__label {
  font-size: 20px;
}

.copiable {
  cursor: copy;
  transition: 0.15s linear;
}

.copiable:hover {
  background-color: rgba(255, 255, 2555, 0.3);
}

.add-network-button {
  white-space: nowrap;
}

.modal-backdrop {
  z-index: 1050;
}

.modal {
  .modal-header {
    border-bottom: none;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .modal-body {
    padding-left: 0;
    padding-right: 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .modal-footer {
    border-top: none;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.modal-close-button {
  appearance: none;
  position: absolute;
  right: 16px;
  top: 16px;
  outline: none;
  border: none;
  background: none;
  z-index: 100;

  svg {
    width: 24px;
    height: 24px;
  }
}

.modal-close-button:hover svg path {
  fill: var(--color-text-red);
}

.fs-tabs {
  &.nav-tabs {
    border: none;
    outline: none;
    gap: 32px;
  }

  .nav-item {
    margin: 0;
    border: none;
    outline: none;
    padding: 0 0 16px;
    font-size: 24px;
    line-height: 1.08;
    letter-spacing: 1px;
    text-transform: initial;
    position: relative;

    &:hover,
    &:active,
    &:focus {
      border: none;
    }

    &.active {
      font-weight: 700;
      color: var(--color-text-violet);
      background-color: transparent;
      border: none;
    }
  }
}

.fs-tabs-rounded {
  &.nav-tabs {
    border-radius: 50px;
    background-color: var(--color-background);
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    border: 1px solid var(--color-main-grey);

    @include media-breakpoint-down(md) {
      padding: 4px;
    }
  }

  .nav-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 16px;
    border: none;
    border-radius: 50px;
    flex: 1 auto;
    text-transform: capitalize;
    margin: 0;
    text-align: center;
    background-color: transparent;
    color: var(--color-text-white);

    &.active {
      font-weight: 700;
      background-color: var(--color-main-dark);
      color: var(--color-text-white);
    }

    &:after {
      content: none;
    }

    @include media-breakpoint-down(md) {
      font-size: 12px;
      padding: 10px;
      letter-spacing: 1px;

      &:hover {
        color: var(--color-text-white);
      }
    }
  }
}

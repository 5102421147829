@import "src/styles/mixins";

.portfolio-list {
  --portfolio-columns: 7fr repeat(4, 2.5fr) 5fr;

  @include media-breakpoint-down(xl) {
    --portfolio-columns: 6fr repeat(4, 2.5fr) 5fr;
  }
}

.portfolio-list .no-projects-tile.hidden {
  display: none;
}

.portfolio-list__body:empty + .no-projects-tile.hidden {
  display: flex;
}

.portfolio-list__body .accordion {

  @include media-breakpoint-down(lg) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.portfolio-list__body > .loader {
  display: block;
  margin: 35px auto 0;
}

.portfolio-list__header {
  list-style: none;
  padding: 16px 40px;
  margin: 0;
  display: grid;
  grid-gap: 18px;
  grid-template-columns: var(--portfolio-columns);

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.portfolio-list__header li {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  line-height: 1.26;
  letter-spacing: 1px;
  color: var(--color-text-white-transparantize);
}

.portfolio-list__header li:first-child {
  justify-content: flex-start;
}

.portfolio-list__header li:last-child {
  justify-content: flex-start;
}

.portfolio-list .portfolio-item {
  margin-bottom: 24px;
}

@import 'src/styles/mixins';

.join-our-network-page .marketing-content {
  height: 100%;
}

.join-our-network {
  height: 100%;
}

.join-our-network-page.marketing-page {
  h1.title {
    text-align: center;

    @include media-breakpoint-down(sm) {
      text-align: left;
    }
  }
}

.join-our-network-page:before {
  position: absolute;
  content: '';
  top: -96px;
  left: 0;
  width: 100vw;
  height: 81vw;
  background-image: url('../../../assets/investors-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

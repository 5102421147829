.lottery-placeholder {
  &.tile {
    background-color: #23282F66;
  }

  &__image {
    margin-bottom: 16px;
    width: 100%;
  }

  &__message {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.26;
  }
}
@import "src/styles/mixins";

.account-staking {
  padding-top: 45px;

  .stake-allocation-section .ellipse18 {
    top: 21%;
  }
}

.account-staking__stake-block {
  justify-content: space-between;
}

.stake-block-tab-stake {
  .info-list {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .value {
      font-size: 24px;
      letter-spacing: .5px;
    }
  }

  .stake-block__buttons {
    @include media-breakpoint-down(xl) {
      flex-direction: column;
    }
  }
}

.stake-block-tab-unstake .stake-block__buttons {
  @include media-breakpoint-down(xl) {
    flex-direction: column;
  }
}

.stake-block .tooltip-wrapper {
  margin-left: 8px;
}

.stake-block__info {
  margin-top: 20px;
}

.my-stakes {
  h1 {
    font-weight: 600;
    margin-bottom: 16px;
  }

  .info-list {
    margin: unset;
  }

  .info-list__item {
    &.top-item {
      padding-top: 0;
      padding-bottom: 16px;
      border-bottom: 1px solid var(--color-main-grey);
    }

    &.bottom-item {
      padding-bottom: 0;
    }
  }
}

.estimated-rewards {
  border: 1px solid var(--color-main-grey);
  border-radius: 20px;
  padding: 16px;
  margin-bottom: 40px;
}

.stake-form__heading__title {
  font-size: 24px;
  font-weight: 700;

  @include media-breakpoint-down(lg) {
    font-size: 16px;
  }
}

.balances.tile {
  margin-bottom: 24px;
}

.stake-block__info .info-list__item .value {
  font-size: 24px;
  letter-spacing: 1px;
  font-weight: 700;

  @include media-breakpoint-down(xl) {
    font-size: 20px;
  }

  @include media-breakpoint-down(lg) {
    font-size: 16px;
  }
}

.account-staking__stake-block {
  .balance-item__main {
    font-size: 26px;

    @include media-breakpoint-down(xl) {
      font-size: 20px;
    }
  }
}

.stake-form__heading {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  p {
    margin-bottom: 3px;
  }

  .get-the-token {
    display: flex;
    flex-direction: column;
  }

  & > div {
    margin-left: 12px;
  }

  .round-button {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    line-height: 13px;
    padding: 5px;
  }
}


.stake-form {
  .form-message {
    margin-bottom: 30px;
  }

  .tab-content .tab-pane.active {
    display: flex;
    flex-direction: column;
  }

  @include media-breakpoint-down(md) {
    margin-top: 24px;
  }
}

.staking-stats {
  margin-top: 14px;

  .value {
    text-align: right;
    width: 40%;
  }

  .name {
    width: 40%;
  }

  &.info-list {
    margin: 0;

    .info-list__item {
      margin: 0;
      border-bottom: 1px solid var(--color-main-grey);
      padding-top: 30px;
      padding-bottom: 30px;

      &.first-item {
        padding-top: unset;
      }

      &.last-item {
        padding-bottom: unset;
        border-bottom: none;
      }
    }
  }
}

.stake-block-tabs {
  &.nav-tabs {
    border-radius: 50px;
    background-color: var(--color-background);
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    border: 1px solid var(--color-main-grey);

    @include media-breakpoint-down(md) {
      padding: 3px;
    }
  }

  .nav-link.nav-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 1;
    padding: 16px !important;
    font-weight: 500;
    border: none;
    border-radius: 50px;
    width: 33%;
    text-transform: capitalize;
    margin: unset;
    text-align: center;

    @include media-breakpoint-down(md) {
      height: 32px;
    }

    &.active {
      font-weight: 700;
      color: var(--color-main-yellow);
      background-color: var(--color-main-dark);
    }

    &::after {
      display: none;
    }
  }
}







.lottery-rules-modal {
  font-size: 20px;
  line-height: 180%;

  h2 {
    font-size: 40px;
    font-weight: 600;
    margin: 32px 0;
  }

  h3 {
    font-size: 24px;
    font-weight: 600;
    margin: 28px 0;
  }

  .custom-scroll {
    padding-right: 20px;
    max-height: 60vh;
  }

  .modal-dialog {
    padding: 10px;
    max-width: 1024px;
    width: 100%;

    .tile {
      backdrop-filter: none;
      padding: 40px;
      background-color: #15161c;
    }
  }

  &__close {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    background-color: var(--color-lottery-bg-transparent);
    font-size: 0.9rem;
    border: 1px solid;
    margin: 1.4em 0;

    th,
    td {
      padding: 10px;
      text-align: left;
      vertical-align: middle;
      border: 1px solid;
      line-height: 1.4;
    }

    ul, ol {
      display: inline-block;
      position: relative;
      margin: auto;
      padding-left: 0;
    }

    li {
      list-style: none;
    }

    th:first-child {
      max-width: 180px;
    }
  }
}
@import "src/styles/mixins";

.loot-box {
  &.tile {
    padding: 40px 40px 12px;
    position: relative;
  }

  &__main {
    display: grid;
    grid-template-columns: var(--boxes-columns);
    padding: 0 0 24px;
    grid-gap: 18px;
  }

  &__col {
    display: flex;
    align-items: center;
    @include gap(16px);

    @include media-breakpoint-down(lg) {
      justify-content: space-between;
    }

    h4 {
      font-size: 32px;
      font-weight: 600;
    }

    span {
      font-weight: 700;
      letter-spacing: 1px;

      @include media-breakpoint-up(lg) {
        text-align: center;
      }

      @include media-breakpoint-down(lg) {
        flex-basis: 50%;
        br {
          display: none;
        }
      }

      &:not(:first-child) {
        text-transform: uppercase;
        text-align: right;
      }

      &:first-child {
        color: var(--color-text-white-transparantize);
        font-weight: 400;
        white-space: nowrap;

        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
    }

    .round-button {
      white-space: nowrap;
      width: 100%;
    }

    @include media-breakpoint-up(lg) {
      &:not(:first-child) {
        justify-content: center;
      }
    }
  }

  &__name {
    margin-bottom: 0;

    a {
      text-decoration: none;
    }
  }

  &__col--wrong-network {
    display: grid;
    font-size: 18px;

    @include media-breakpoint-up(lg) {
      padding-left: 32px;
      grid-column-start: 2;
      grid-column-end: 7;
    }
  }
}



@import 'src/styles/mixins';

.top-banner {
  height: 60vh;
  max-width: 1600px;
  width: 100%;
  margin: auto;
  top: -10px;
  max-height: 530px;

  @include media-breakpoint-down(lg) {
    top: unset;
  }

  .container {
    height: 100%;
  }

  &__cards-left, &__cards-right {
    width: 43%;
    position: absolute;
    margin-right: -55px;
    margin-left: -35px;
    z-index: 0;
  }

  &__cards-left {
    left: 0;
    bottom: 0;
  }

  &__cards-right {
    top: 0;
    right: 0;
  }

  &__spaceman {
    width: 8%;
    top: 15%;
    right: 34%;
    position: absolute;
    z-index: 1;
  }
}

@include media-breakpoint-down(xxl) {
  .top-banner {
    max-height: 470px;

    &__cards-left, &__cards-right {
      width: 45%;
    }

    &__spaceman {
      top: 15%;
      right: 35%;
    }
  }
}

@include media-breakpoint-down(xl) {
  .top-banner {
    max-height: 430px;

    &__cards-left, &__cards-right {
      width: 50%;
    }

    &__spaceman {
      top: 17%;
      right: 39%;
      width: 8%;
    }
  }
}


@include media-breakpoint-down(lg) {
  .top-banner {
    max-height: 435px;

    &__cards-left, &__cards-right {
      width: 55%;
    }

    &__spaceman {
      top: 16%;
      right: 42%;
      width: 10%;
    }
  }
}

@include media-breakpoint-down(md) {
  .top-banner {
    max-height: 420px;

    &__cards-left, &__cards-right {
      width: 60%;
    }

    &__spaceman {
      right: 46%;
      top: 15%;
      width: 12%;
    }
  }
}

@include media-breakpoint-down(sm) {
  .top-banner {
    max-height: 400px;

    &__cards-left, &__cards-right {
      width: 65%;
    }

    &__spaceman {
      right: 47%;
      width: 12%;
    }
  }
}

@include media-breakpoint-down(xs) {
  .top-banner {
    max-height: 380px;

    &__cards-left, &__cards-right {
      width: 75%;
    }

    &__spaceman {
      top: 15%;
      right: 51%;
      width: 17%;
    }
  }
}

@include media-breakpoint-down(xs) {
  .top-banner {
    max-height: 370px;

    &__cards-left, &__cards-right {
      width: 80%;
    }

    &__spaceman {
      top: 18%;
      right: 55%;
      width: 17%;
    }
  }
}

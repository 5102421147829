@import "src/styles/mixins";

.spinner-wrapper {
  display: flex;
  justify-content: center;
  margin: 48px auto;
}

.linking-wallets {

  &__caption {
    font-size: 24px;
  }
  ol {
    padding-left: 1rem;
    line-height: 1.5;

    li + li {
      margin-top: 12px;
    }
  }
}

.linking-wallets-status {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    justify-content: center;
  }
}

.linking-wallets-status__text {
  margin-bottom: 0;
  line-height: 180%;
  text-align: left;

  .wallet-address {
    margin-top: 12px;
  }

  .add-network-button {
    @include media-breakpoint-down(md) {
      margin-top: 25px;
      width: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.linking-wallets-form {
  background: transparent !important;
  backdrop-filter: unset !important;
  padding: 0 !important;
}

.linking-wallets-form__description {
  padding: 0 35px;
  margin-bottom: 24px;
}

.linking-wallets-form__submit-button {
  width: 100%;
}

.linking-wallets-form__submit-button .loader {
  vertical-align: middle;
}

.link-wallet-status__icon {
  height: 32px;
  width: auto;
  margin-right: 8px;
}

.link-wallet-status__network {
  vertical-align: center;
  white-space: nowrap;
}

.link-wallet-status__tooltip {
  max-width: 600px;
}

.link-wallet-dialogue__link-button {
  flex-shrink: 0;

  @include media-breakpoint-down(md) {
    margin-top: 25px;
  }
}

.wallet-address {
  display: flex;
  align-items: center;
  gap: 8px;

  > svg {
    width: 24px;
    height: 24px;
  }

  .copiable {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__address {
    color: var(--color-text-violet);
  }
}

.timer-values {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-text-white);
    --highlight-color: var(--color-text-violet)
}

.timer-values__item {
    margin: 0 0.5rem;
}

.timer-values__item .value {
    font-size: 24px;
    font-weight: 600;
    line-height: 125%;
    color: var(--highlight-color);
    text-align: center;
}

.timer-values__item.seconds .value {
    color: var(--color-text-white-transparantize);
}

.timer-values__item .name {
    font-size: 12px;
    line-height: 144%;
    text-align: center;
    text-transform: capitalize;
    letter-spacing: 1px;
}

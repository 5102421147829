.network-switcher {
  $p: &;

  &__dropdown {
    &.btn-group {
      border-radius: 27px;
      border: 1px solid var(--color-separator);
    }

    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    #{$p}__toggle,
    &:not(.show) > #{$p}__toggle:focus {
      display: flex;
      align-items: center;
      color: inherit;
      border: none;
      text-transform: uppercase;
      font-weight: 700;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding: 2px 11px;
    }

    &.show > .btn-primary.dropdown-toggle,
    .btn-primary.dropdown-toggle:hover {
      color: inherit;
      background-color: inherit;
    }

    .network-switcher__item {
      display: flex;
      align-items: center;
      cursor: pointer;
      text-transform: uppercase;

      &.selected {
        pointer-events: none;
        font-weight: 700;
      }
    }
  }

  &__icon {
    height: 32px;
    width: auto;
    margin-right: 10px;
  }
}

.project-tag {
  display: flex;
  align-items: center;
  padding: 6px 8px;
  border-radius: 10px;
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.2);
  font-size: 16px;
  font-weight: 500;
  line-height: 126%;
  letter-spacing: 0.5px;
  white-space: nowrap;
}

.project-tag__image {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}

@media (max-width: 1199px) {
  .project-tag.badge {
    font-size: 12px;
    letter-spacing: 1px;
  }
}

.project-tag.coming-soon {
  background: linear-gradient(91.53deg, #FF833D 1.3%, #FF9E67 98.7%);
}

.project-tag.registration-open {
  background: linear-gradient(91.53deg, #0B96B5 1.3%, #02C88D 98.7%);
}

.project-tag.registration-closed {
  background: linear-gradient(91.53deg, #0B96B5 1.3%, #48505C 98.7%);
}

.project-tag.guaranteed-live {
  background: linear-gradient(42.26deg, #EF8BFF 33.95%, #9946fe 138.93%);
}

.project-tag.fcfs-coming {
  background: linear-gradient(42.26deg, #8729cf 33.95%, #160034 138.93%);
}

.project-tag.fcfs-live {
  background: linear-gradient(90deg, #FE4646 0%, #FF7272 100%);
}

.project-tag.closed {
  background: linear-gradient(90deg, #444B56 0%, #5F6978 100%);
}

.project-tag--project {
  background: white;
}

.project-tag--project span {
  background-color: #6E2791;
  background-image: linear-gradient(90deg, #6E2791 0%, #008BFF 100%);
  background-size: 100%;
  font-weight: bold;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.project-tag--project .tooltip-wrapper svg path {
  fill: #212326;
}

.project-tag--project .tooltip-wrapper {
  display: flex;
  margin-left: 6px;
}

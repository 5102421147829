@import "src/styles/mixins";

.nft-card {
  --card-border: 1px;
  position: relative;
  display: block;
  width: 392px;
  padding: 12px;
  border-radius: 32px;
  border: var(--card-border) solid transparent;
  background-color: #15161C;
  background-clip: padding-box;
  text-decoration: none;
  color: inherit;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: inherit;
    margin: calc(-1 * var(--card-border));
    z-index: -1;
    background: linear-gradient(144.81deg, rgba(255, 169, 237, 0.89) -0.29%, rgba(255, 255, 255, 0) 108.35%);
    transition: 0.15s linear;
  }

  &:hover {
    color: inherit;

    &:before {
      opacity: 0;
    }
  }

  @include media-breakpoint-down(lg) {
    border-radius: 24px;
    width: 324px;
  }

  @include media-breakpoint-down(md) {
    width: 289px;
  }

  .loader {
    margin: auto;
  }

  &__asset-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    border-radius: 24px;
    width: 100%;
    height: 393px;
    background-color: var(--color-background);

    @include media-breakpoint-down(lg) {
      border-radius: 16px;
      height: 310px;
    }

    @include media-breakpoint-down(md) {
      height: 273px;
    }
  }

  &__asset {
    width: 100%;
    border-radius: 24px;

    @supports (object-fit: cover) {
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    @supports not (object-fit: cover) {
      overflow: hidden;
    }
  }

  &__content {
    display: flex;
    align-items: flex-start;
    padding: 12px;
  }

  &__icon {
    width: 43px;
    margin-right: 16px;
  }

  &__title {
    margin-bottom: 2px;
  }

  &__id {
    line-height: 1.5;
    margin: 0;
    letter-spacing: 0.5px;
    color: var(--color-text-white-transparantize);
  }
}


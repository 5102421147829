@import 'src/styles/mixins';

.card.accordion span {
  display: block;
}

.card.accordion {
  font-size: 18px;
  background: #15171C;
  box-shadow: 0px 14px 100px rgba(0, 0, 0, 0.15);
  border-radius: 26px;
  margin-bottom: 1rem;
  text-align: left;

  @include media-breakpoint-down(md) {
    font-size: 16px;
  }

  .collapse {
    padding-top: 0;
    line-height: 150%;
  }

  .card-header {
    border: 0;
    padding: 0;
  }

  .btn {
    display: flex;
    align-self: center;
    justify-content: space-between;
    align-items: center;
    width: 100% !important;
    text-decoration: none;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 600;
    color: var(--color-text-white);
    border-color: transparent;
    padding: 32px 33px;
    text-align: left;

    @include media-breakpoint-down(md) {
      font-size: 16px;
      padding: 32px 24px;
    }

    svg path {
      fill: currentColor;
    }

    span {
      transition: 0.3s linear;
    }

    &.active,
    &:active {
      color: var(--color-text-violet);
      border-color: transparent;
    }

    &.active {
      span {
        transform: rotate(90deg);
        @include media-breakpoint-down(md) {
          font-size: 16px;
        }
      }
    }

    .arrow svg {
      @include media-breakpoint-down(sm) {
        width: 12px;
        height: 7px;
      }
    }
  }

  .card-body {
    padding: 0 8rem 2.25rem 2.25rem;
    color: var(--color-text-white);

    @include media-breakpoint-down(md) {
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 1rem;
    }
  }
}

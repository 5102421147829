.increment-input {
  display: flex;
  align-items: center;
  gap: 8px;

  .form-control {
    font-size: 24px;
    width: 6rem;
    text-align: right;

    &,
    &:active,
    &:focus {
      padding: 10px 16px;
    }

    &[readonly] {
      color: var(--color-text-white);
    }
  }
}

@import "src/styles/mixins";

.cms-title {
  font-weight: 600;
  line-height: 1.26;
  margin-bottom: 8px;
}

h2.cms-title {
  font-size: 40px;
}

h3.cms-title {
  font-size: 32px;
}

h4.cms-title {
  font-size: 24px;
}

h5.cms-title {
  font-size: 20px;
}

h6.cms-title {
  font-size: 16px;
}

@include media-breakpoint-down(lg) {
  h2.cms-title {
    font-size: 32px;
  }

  h3.cms-title {
    font-size: 28px;
  }
}

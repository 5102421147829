@import "src/styles/mixins";

.leaderboard-list.tile {
  background-color: rgba(35, 40, 47, 0.4);
  backdrop-filter: blur(80px);

  @include media-breakpoint-down(md) {
    padding: 15px;
  }
}

.leaders-list__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  padding: 5px 0 16px;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }
}

.leaders-list__selections {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  @include gap(20px);
  margin-right: auto;

  @include media-breakpoint-down(lg) {
    margin: unset;
    width: 100%;
  }

  @include media-breakpoint-down(xs) {
    @include gap(10px);
  }
}

.leaders-list__selection-item {
  position: relative;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 1px;
  padding: 0;
  transition: 0.15s linear;
  cursor: pointer;

  @include media-breakpoint-down(md) {
    font-size: 16px;
  }
}

.leaders-list__update-time {
  @include media-breakpoint-down(lg) {
    width: 100%;
    align-items: flex-start;
    order: -1;
    margin-bottom: 15px;
  }
}

.leaders-list__selection-item:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 3px;
  background-color: transparent;
  left: 0;
  bottom: -16px;
  transition: inherit;
}

.leaders-list__selection-item + .leaders-list__selection-item {
  margin-left: 32px;

  @include media-breakpoint-down(lg) {
    margin-left: 0;
  }
}

.leaders-list__selection-item:hover {
  color: var(--color-text-violet);
}

.leaders-list__selection-item.active {
  font-weight: 700;
}

.leaders-list__selection-item.active:after {
  background-color: var(--color-text-violet);
}

.leaderboard-table-wrapper {
  overflow: hidden;
  border-radius: 16px;
  border: 1px solid #2D3449;
}

.leaderboard-table .simple-table__head-row {
  background-color: rgba(255, 255, 255, 0.04);
  height: 52px;
}

.leaderboard-table .simple-table__row {
  height: 90px;

  @include media-breakpoint-down(lg) {
    height: 100%;
  }

  background: rgba(11, 11, 15, 0.4);
}

.leaderboard-table :is(th, td) {
  line-height: 1.25;
  letter-spacing: 0.5px;
  vertical-align: middle;
  padding: 10px;

  @include media-breakpoint-down(lg) {
    padding: 5px;
  }
}

.leaderboard-table th {
  color: var(--color-text-white-transparantize);
  font-weight: 400;
}

.leaderboard-table .simple-table__row.highlighted {
  font-weight: 700;
  color: var(--color-text-violet);
}

.leaderboard-table tr {
  border-bottom: 1px solid #2D3449;
}

.leaderboard-table-wrapper .simple-table {

  @include media-breakpoint-down(lg) {

    .simple-table__cell img {
      width: 30px;
      height: 30px;
    }

    .simple-table__row {
      padding: 10px;
    }

    & thead {
      display: none;
    }

    &, & tbody, & tr, & td {
      display: block;
      width: 100%;
    }

    & td {
      text-align: right;
      position: relative;
      display: flex;
      width: 100%;
      justify-content: space-between;
      column-span: 4;
      row-span: 2;
      font-size: 20px;
    }

    & td::before {
      content: attr(data-label);
      font-size: 20px;
      font-weight: normal;
      text-align: left;
      color: var(--color-text-white-transparantize);
    }

    & td.first::before {
      font-weight: bold;
      color: var(--color-text-violet);
    }

    & td, & th {
      align-items: center;

      &:not(:first-child):not(:last-child) {
        text-align: right;
      }
    }

    &__row:last-child .simple-table__cell:last-child {
      margin-bottom: 0;
    }
  }

  & td.loading {
    justify-content: center;
  }

  @include media-breakpoint-down(md) {
    & td::before {
      font-size: 16px;
    }

    & td {
      font-size: 16px;
    }
  }
}

.token-address__copy {
  margin-left: 5px;
}

.leaderboard-table__wallet {
  & > div {
    display: flex;
    align-items: center;

    & + div {
      margin-top: 8px;
    }
  }

  &-icon {
    margin-right: 5px;
  }
}

@import "src/styles/mixins";

.round-button, .btn.round-button {
  position: relative;
  font-family: Archivo !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: var(--color-btn-bg, var(--color-text-white));
  color: var(--color-btn-text, #181A1B);
  border: 0;
  border-color: var(--color-btn-border, var(--color-text-white));
  border-radius: 25px;
  padding-left: 20px;
  padding-right: 20px;

  &.light {
    --color-btn-bg: var(--color-text-white);
    --color-btn-text: #181A1B;
    --color-btn-border: var(--color-text-white);
  }

  &.dark {
    --color-btn-bg: rgba(255, 255, 255, 0.15);
    --color-btn-text: var(--color-text-white);
    --color-btn-border: rgba(255, 255, 255, 0.15);
  }

  &.transparent {
    --color-btn-text: var(--color-text-white);
    --color-btn-bg: rgba(0, 0, 0, 0.25);
    --color-btn-border: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(40px);

    &:disabled:hover {
      color: var(--color-text-white);
    }
  }

  &:not(:disabled):hover {
    color: var(--color-text-violet);
    background-color: var(--color-btn-bg);
    border-color: var(--color-btn-border);

    .link-arrow path {
      fill: var(--color-text-violet);
    }
  }

  &:disabled,
  &.disabled {
    pointer-events: auto;
    cursor: not-allowed;
    opacity: 0.65;
    color: var(--color-btn-text);
    background-color: var(--color-btn-bg);
    border-color: var(--color-btn-border);
  }

  &--with-badge:disabled {
    opacity: 1;
  }

  &.large {
    border-radius: 30px;
    line-height: 46px;
    padding-left: 25px;
    padding-right: 25px;
  }

  &.small {
    line-height: 28px;
    padding-left: 16px;
    padding-right: 16px;
  }

  &.wide {
    padding-left: 66px;
    padding-right: 66px;
  }

  &__badge {
    position: absolute;
    top: -20px;
    left: 8px;
    font-size: 12px;
    line-height: 1.26;
    color: var(--color-background);
    padding: 6px 12px;
    background-color: var(--color-text-white);
    border: 2px solid var(--color-background);
    border-radius: 20px;
    text-transform: none;
    transform: rotate(-3.93deg);
  }

  &.loading {
    &:after {
      position: absolute;
      content: '';
      inset: 0;
      background-color: rgba(255, 255, 255, 0.1);
      z-index: 1;
      border-radius: inherit;
    }

    .loader {
      position: absolute;
      z-index: 2;
      left: calc(50% - var(--loader-size) / 2);
      top: calc(50% - var(--loader-size) / 2);
      margin: 0;
    }
  }

  .link-arrow path {
    fill: currentColor;
    transition: .15s;
  }

  svg {
    height: 20px;
    margin-left: 5px;
  }

  .link-arrow {
    height: 1.2em;
    width: 1.2em;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  & * + .loader {
    margin-left: 6px;
  }

  .loader:not(:last-child) {
    margin-right: 6px;
  }

  @include media-breakpoint-down(xxl) {
    font-size: 14px;
    line-height: 32px;
    padding-left: 16px;
    padding-right: 16px;

    &.large {
      line-height: 42px;
      padding-left: 25px;
      padding-right: 25px;
    }

    &.wide {
      padding-left: 55px;
      padding-right: 55px;
    }

    &.small {
      line-height: 28px;
    }
  }
}

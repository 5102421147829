@import "src/styles/mixins";

$nft-card-shadow-color: #5d5f61;

.fs-nft-section {
  perspective: 1000px;

  .nft-card {
    pointer-events: none;

    &:before {
      content: none;
    }
  }

  &__heading {
    max-width: 840px;
    margin-bottom: 80px;

    @include media-breakpoint-down(md) {
      margin-bottom: 32px;
    }
  }

  .title {
    margin-bottom: 16px;
  }

  .subtitle {
    font-size: 24px;
    line-height: 1.5;
    letter-spacing: 0.5px;
    margin-bottom: 32px;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }

  &__description {
    position: relative;
    padding: 78px 120px;
    border-radius: 32px;
    max-width: 996px;
    border: 1px solid transparent;
    background-color: var(--color-background);
    background-clip: padding-box;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: -1px;
      z-index: -1;
      border-radius: inherit;
      background: linear-gradient(64.46deg, rgba(255, 255, 255, 0.22) 28.7%, rgba(255, 255, 255, 0) 85.49%)
    }

    @include media-breakpoint-down(md) {
      padding: 24px;
      border-radius: 24px;
    }

    .title {
      font-size: 32px;

      @include media-breakpoint-down(md) {
        font-size: 24px;
      }
    }

    dl {
      margin-bottom: 28px;
    }

    dt,
    dd {
      display: inline;
    }

    dt {
      font-weight: 400;
    }

    dd {
      font-weight: 700;
    }
  }

  &__description-list {
    ul {
      padding-left: 1rem;
    }

    & ~ p {
      margin-top: 28px;
      margin-bottom: 0;
    }
  }

  &__card-container {
    position: absolute;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
    z-index: 2;

    @include media-breakpoint-down(lg) {
      display: none;
    }

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      opacity: 0.5;
    }
  }
}

.flare-section {
  position: relative;
  margin-top: 80px;
  padding-top: 80px;
  padding-bottom: 180px;
  background-image: url('../../../assets/nft/flare-bg.png');
  background-size: cover;
  background-position: center;

  @include media-breakpoint-down(md) {
    margin-top: 0;
    padding-top: 32px;
    padding-bottom: 56px;
  }

  .nft-card {
    $card-transform:
      perspective(600px)
      rotateY(-30deg)
      rotateZ(6deg)
      rotateX(10deg);
    transform-style: preserve-3d;
    transform: $card-transform;
    box-shadow: 3px 2px 0 1px $nft-card-shadow-color;

    @include media-breakpoint-down(xl) {
      transform: $card-transform scale(0.85);
    }

    @include media-breakpoint-down(sm) {
      transform: $card-transform scale(0.7);
    }
  }
}


.hiro-section {
  padding-top: 180px;
  padding-bottom: 120px;
  background-image: url('../../../assets/nft/hiro-bg.png');
  background-size: cover;
  background-position: center;

  @include media-breakpoint-down(md) {
    padding-top: 32px;
    padding-bottom: 56px;
  }

  &__caption {
    font-size: 32px;
    font-weight: 500;
    line-height: 1.26;
    letter-spacing: 1px;
    margin-bottom: 8px;
  }

  &__heading {
    max-width: 809px;
    @media (max-width: 1439px) {
      max-width: 650px;
    }

    .timer-values {
      justify-content: flex-start;
      --highlight-color: var(--color-text-violet);
    }

    .timer-values__item {
      margin: 0 0.75rem;
    }
  }

  .nft-card {
    $card-transform:
      perspective(600px)
      rotateY(23deg)
      rotateZ(-10deg)
      rotateX(10deg);

    transform-style: preserve-3d;
    transform: $card-transform;
    box-shadow: -3px 2px 0 1px $nft-card-shadow-color;

    @include media-breakpoint-down(xl) {
      transform: $card-transform scale(0.85);
    }

    @include media-breakpoint-down(sm) {
      transform: $card-transform scale(0.7);
    }
  }

  &__card-container {
    .nft-card {
      margin-top: 30%;

      @include media-breakpoint-down(xl) {
        margin-top: 50%;
      }
    }

    &.coming-soon .nft-card:after {
      position: absolute;
      content: 'Coming soon...';
      color: var(--color-text-white);
      top: 40%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 24px;
      font-weight: 600;
    }
  }
}

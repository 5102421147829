@import 'src/styles/mixins';

.approval-steps {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  @include gap(8px);
}

.approval-steps__label {
  text-align: center;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 1px;
  color: #FFFFFF;
  opacity: 0.5;

  @include media-breakpoint-down(md) {
    font-size: 12px;
  }
}

.approval-steps__label.active {
  opacity: 1;
}

.approval-steps__line {
  width: 200px;
  height: 4px;
  border-radius: 3px;
  background: linear-gradient(108.68deg, #C4A2FF 4.9%, rgba(255, 147, 147, 0.42) 54.14%, rgba(255, 255, 255, 0) 100%);
}

.approval-steps__line.filled {
  background: linear-gradient(108.68deg, #C4A2FF 4.9%, #FF9393 74.14%);
}

@import "src/styles/mixins";

$background-image: url('../../../assets/lottery-banner/bg.png');
$background-image-mobile: url('../../../assets/lottery-banner/bg-mobile.png');

.lottery-banner {
  margin: 70px 0 80px;

  @include media-breakpoint-down(lg) {
    margin: 32px 0;
  }

  &__card {
    display: flex;
    position: relative;
    min-height: 600px;
    width: 100%;
    background-image: $background-image;
    background-size: cover;
    background-position: 0 0;
    background-repeat: no-repeat;
    border-radius: 24px;
    box-shadow: 1px 1px 2px 0px rgba(255, 255, 255, 0.15) inset;
    z-index: 1;
    overflow: hidden;
    align-items: stretch;

    @include media-breakpoint-down(lg) {
      background-image: $background-image-mobile;
      flex-direction: column;
    }
  }

  &__spaceman {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    pointer-events: none;

    @include media-breakpoint-down(lg) {
      min-width: 180px;
      width: 40%;
      bottom: max(30%, calc(50% - 90px));
      z-index: 3;
    }
  }

  &__ufo {
    position: absolute;
    z-index: 1;
    right: 20%;
    top: 0;
    pointer-events: none;

    @include media-breakpoint-down(lg) {
      width: 45%;
      top: 15%;
      right: 40%;
      z-index: 2;
    }
  }

  &__inner {
    padding: 64px;
    width: 100%;

    &:after {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 3;
      background: linear-gradient(
        90deg,
        #15171c 0%,
        rgba(21, 23, 28, 0.54) 60.94%,
        rgba(11, 11, 15, 0) 100%
      );
    }

    @include media-breakpoint-down(lg) {
      padding: 40px;

      &:after {
        background: linear-gradient(
            178deg,
            rgba(0, 0, 0, 0.00) 1.28%,
            #15171C 73.31%
        );
      }
    }

    @include media-breakpoint-down(sm) {
      padding: 24px;
    }
  }

  &__contents {
    position: relative;
    width: 100%;
    z-index: 4;

    @include media-breakpoint-up(lg) {
      width: 600px;
    }

    @include media-breakpoint-up(xl) {
      width: 700px;
    }
  }

  &__link.round-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    background-color: #07ADA3;
    z-index: 5;

    @include media-breakpoint-up(lg) {
      position: absolute;
      bottom: 45px;
      right: 45px;
    }

    @include media-breakpoint-down(lg) {
      margin-top: 16px;
      height: 45px;
    }

    svg {
      width: 24px;
      height: 24px;

      path {
        fill: currentColor;
      }
    }

    &:hover {
      background-color: var(--color-text-white);
    }
  }
}

@import "src/styles/mixins";

$background-image: url('bg.png');
$border-radius: 20px;

.lottery-phase {
  position: relative;
  $p: &;

  &__badge {
    position: relative;
    width: max-content;
    padding: 4px 12px;
    border-radius: 100px;
    font-size: 12px;
    line-height: 1.26;
    font-weight: 700;
    letter-spacing: normal;
    color: var(--color-background);
    background-color: #8a8a8d;

    @include media-breakpoint-down(lg) {
      border: 2px solid var(--color-background);
      position: absolute;
      top: -12px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__heading {
    font-size: 64px;
    font-weight: 700;
    line-height: 112%;

    @include media-breakpoint-down(lg) {
      font-size: 40px;
    }

    @include media-breakpoint-down(md) {
      font-size: 24px;
    }
  }

  &__desc {
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 0.5px;

    @include media-breakpoint-down(lg) {
      margin-bottom: 180px;
    }

    @include media-breakpoint-down(md) {
      font-size: 16px;
      margin-bottom: 140px;
    }
  }

  &__wrapper {
    position: relative;
    display: block;
  }

  &__current {
    position: relative;
    border-radius: $border-radius;
    background-color: var(--color-lottery-bg);
    padding: 40px;
    border: 1px solid;

    @include media-breakpoint-down(lg) {
      padding: 24px;
    }

    @include media-breakpoint-down(md) {
      padding: 24px 16px 16px;
    }

    #{$p}__badge {
      background-color: var(--color-text-white);

      @include media-breakpoint-up(lg) {
        position: absolute;
        top: -12px;
        left: 40px;
      }
    }

    &.active {
      border-color: var(--color-text-violet);

      #{$p}__badge {
        background-color: var(--color-text-violet);
      }
    }
  }

  &__prev,
  &__next {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 32px;
    background-color: var(--color-lottery-bg-transparent);
    padding: 20px 24px;
    color: #888;

    @include media-breakpoint-down(lg) {
      margin: 0 24px;
    }

    @include media-breakpoint-down(md) {
      margin: 0 16px;
      padding: 14px 16px;
    }
  }

  &__prev {
    border-radius: $border-radius $border-radius 0 0;
  }

  &__next {
    border-radius: 0 0 $border-radius $border-radius;

    @include media-breakpoint-down(lg) {
      #{$p}__badge {
        position: unset;
        top: unset;
        left: unset;
        transform: unset;
      }
    }
  }

  &__info {
    display: grid;
    grid-template-columns: minmax(240px, 1fr) 200px;
    grid-auto-rows: auto;
    align-items: start;
    grid-gap: 24px 28px;
    color: #b9b9b9;

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr 1.2fr;
      grid-gap: 12px;
      align-items: center;
    }
  }

  &__rules {
    justify-self: center;

    .round-button {
      @include media-breakpoint-down(sm) {
        padding: 8px;
        font-size: 12px;
        line-height: 1.26;
      }
    }
  }

  &__phase-title {
    font-size: 40px;
    font-weight: 600;
    line-height: 126%;
    color: var(--color-text-white);

    @include media-breakpoint-down(md) {
      font-size: 24px;
    }
  }

  &__prize {
    display: block;
    font-size: 16px;

    strong {
      position: relative;
      display: block;
      color: var(--color-text-white);
      font-size: 32px;
      font-weight: 600;
      line-height: 126%;
      letter-spacing: 1px;
    }

    @include media-breakpoint-down(md) {
      font-size: 12px;
      grid-column: 1 / span 2;

      strong {
        font-size: 16px;
      }

      small {
        font-size: 12px;
      }
    }
  }

  &__countdown {
    .timer-values {
      align-items: start;
      justify-content: space-between;
      &__item {
        margin: 0;

        .value {
          font-size: 24px;
        }

        .name {
          font-size: 12px;
        }

        + .timer-values__item {
          margin-left: 0.3rem;
        }
      }

      @include media-breakpoint-down(md) {
        justify-content: start;
      }
    }

    @include media-breakpoint-down(md) {
      grid-column: 1 / span 2;
    }
  }

  &__live-title {
    display: block;
    font-size: 50px;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;

    @include media-breakpoint-down(md) {
      font-size: 32px;
      grid-column: 2;
    }
  }
}

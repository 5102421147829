@import "src/styles/mixins";

.share-on-socials-modal {
  h2 {
    display: block;
    text-align: center;
    font-size: 32px;
    margin-bottom: 24px;
  }

  .tile {
    padding: 24px 0;
  }
  &__close {
    position: absolute;
    top: 0;
    right: 24px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__buttons {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 24px;

    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;
      max-width: 240px;
      margin: 0 auto;
    }
  }

  &__button {
    border: none;
    font-size: 12px;
    background-color: transparent;
    color: #888;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: color 0.2s;
    padding: 0;

    svg path {
      transition: fill 0.2s;
    }

    &:hover {
      color: var(--color-text-violet);

      svg path {
        fill: var(--color-text-violet);
      }
    }

    .logo {
      background-color: #393b3f;
      width: 54px;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 60px;
    }
  }
}

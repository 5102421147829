@import 'src/styles/mixins';

.projects-section {
  padding-top: var(--section-padding-top);
  padding-bottom: var(--section-padding-bottom);

  @include media-breakpoint-down(lg) {
    padding-top: var(--section-padding-mobile-top);
    padding-bottom: var(--section-padding-mobile-bottom);
  }
}

.projects-section__header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  justify-content: space-between;

  @include media-breakpoint-down(lg) {
    margin-bottom: 10px;
  }
}

.projects-section__header .title {
  line-height: 1;
  margin: 0;
  flex-basis: 210px;

  @include media-breakpoint-down(lg) {
    font-size: 32px;
    flex-basis: 100px;
  }
}

.projects-section__header .projects-btn {
  padding: 5px 30px;
  font-size: 16px;
  @include blurred-dark-background(40px, relative, 0.20);
  color: var(--color-text-white);
  flex-basis: 210px;

  @include media-breakpoint-down(lg) {
    flex-basis: 100px;
  }
}

.projects-section__header.top .projects-nav {
  @include gap(46px);

  @include media-breakpoint-down(lg) {
    @include gap(26px);
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.projects-section__header.below {
  margin-bottom: 25px;

  .projects-nav {
    display: none;
    @include gap(26px);

    @include media-breakpoint-down(md) {
      display: flex;
      justify-content: flex-start;
    }
  }
}

.projects-section__header .projects-nav .nav-link {
  position: relative;
  text-transform: none;
  font-size: 24px;
  letter-spacing: 1px;
  padding: 14px 0;
  background: transparent;

  @include media-breakpoint-down(xs) {
    font-size: 16px;
    padding-bottom: 5px;
  }

  &.active {
    font-weight: bold;
    border: none;
  }

  & .nav-item.active {
    background: transparent;
  }
}

.project-ready {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: radial-gradient(at 80% 10%, rgb(144, 36, 182, .15) 30%, rgb(35, 40, 47, .2) 50%);
  border-radius: 32px;
  padding: 60px;

  @include media-breakpoint-down(sm) {
    padding: 24px;
    height: 270px;
  }

  .round-button {
    width: 100%;
    @include blurred-dark-background(40px);
    color: white;
    font-size: 16px;
  }

  .project-ready__content {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 40px;
    text-align: left;
  }
}
